<template>
    <div class="container-fluid corEngecyber" id="modalAlert">
        <div class="row justify-content-center">
            <div class="col-md-4 mt-5">
                <div class="card shadow-lg border-0 rounded-lg mt-5">
                    <div class="card-header text-center">
                      <img src="../assets/logo.png" class="logo">
                    </div>
                    <div class="card-body">
                        <form>
                            <div class="form-floating mb-3">
                                <input class="form-control" id="inputEmail" type="text"  v-model="email"/>
                                <label for="inputEmail">Email</label>
                            </div>
  
                            <div class="d-flex align-items-center justify-content-center mt-4 mb-0">
                                <button type="submit" class="btn btn-primary" v-on:click="recuperarSenha">Recuperar</button>
                            </div>
                        </form>
                    </div>
                    <div class="card-footer text-center py-3">
                      <button class="btn-sm btn btn-outline-secondary" v-on:click="voltarLoginTela">Voltar para o Login</button>
                    </div>
                </div>
            </div>
        </div>
    </div>        
</template>

<script>
export default {
data: function(){
    return {
    email: "",
    }
},

methods: {
    recuperarSenha: function(event){
        event.preventDefault();
        this.email = this.email.trim();

        if(this.email === ""){
            this.mostrarAlert("modalAlert", "O campo email está vazio", "alert-warning");
        } else {
            this.mostrarAlert("modalAlert", "Os dados de acesso serão enviados no email informado", "alert-success");
        } 
    },

    mostrarAlert: function(idLocal, mensagem, tipoAlert){
        const local = document.getElementById(idLocal);      
        
        const sectionAlert = document.createElement('section');
        sectionAlert.setAttribute('id', 'alertId');
        sectionAlert.style.margin = "20px 20vw";
        sectionAlert.style.textAlign = "center";
        sectionAlert.style.fontWeight = "bold";

        sectionAlert.innerHTML = 
        `<div class="alert ${tipoAlert} alert-dismissible" role="alert">
            <p style="margin-bottom: 0">${mensagem}</p>
        </div>`;
            
        local.appendChild(sectionAlert);

        setTimeout(() => {
            local.removeChild(sectionAlert);
        }, 2000);
    },

    voltarLoginTela: function(){
        this.$router.replace('/');
    }
}
}

</script>

<style scoped>
.corEngecyber {
    background-color: #1271A9 !important;
    height: 100vh
}

.logo {
width: 80px
}

</style>
  