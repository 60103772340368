import axios from "axios";

const getConsumoMateriais = async (token) => {
  const resp = await axios.get(`${process.env.VUE_APP_URL_API}/consumoMaterial`, {headers:{'x-access-token': token}});
  return resp.data;
};

const getConsumoMateriaisPorChamado = async (idChamado, token) => {
  const resp = await axios.get(`${process.env.VUE_APP_URL_API}/consumoMaterial/chamado/${idChamado}`, {headers:{'x-access-token': token}});
  return resp.data;
};

const updateConsumoMaterial = async (idMaterial, objConMaterial, token) => {
    const resp = await axios.put(`${process.env.VUE_APP_URL_API}/consumoMaterial/${idMaterial}`, {...objConMaterial}, {headers:{'x-access-token': token}});
    return resp.status;
  };

export { getConsumoMateriais, getConsumoMateriaisPorChamado, updateConsumoMaterial};