import axios from "axios";

const getTecnicos = async (token) => {
  const resp = await axios.get(`${process.env.VUE_APP_URL_API}/tecnico`, {headers:{'x-access-token': token}});
  return resp.data;
};

const postTecnico = async (objTecnico, token) => {
  const resp = await axios.post(`${process.env.VUE_APP_URL_API}/tecnico`, {...objTecnico}, {headers:{'x-access-token': token}});
  return resp.data;
};

const updateTecnico = async (idTecnico, objTecnico, token) => {
  const resp = await axios.put(`${process.env.VUE_APP_URL_API}/tecnico/${idTecnico}`, {...objTecnico}, {headers:{'x-access-token': token}});
  return resp.data;
};

const deleteTecnico = async (idTecnico, token) => {
  const resp = await axios.delete(`${process.env.VUE_APP_URL_API}/tecnico/${idTecnico}`, {headers:{'x-access-token': token}});
  return resp.status;
};

export { getTecnicos, postTecnico, updateTecnico,  deleteTecnico};