<template>
  <div class="container">
    <div class="row justify-content-center align-content-center">
      <div class="loader"></div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<!-- https://www.w3schools.com/howto/howto_css_loader.asp -->
<style scoped>
.container {
  height: 300vh;
}
.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #1271A9; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  margin-top: 20%;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>