import axios from "axios";

const getSindicos = async (token) => {
  const resp = await axios.get(`${process.env.VUE_APP_URL_API}/sindico`, {headers:{'x-access-token': token}});
  return resp.data;
};

const postSindico = async (objSindico, token) => {
  const resp = await axios.post(`${process.env.VUE_APP_URL_API}/sindico`, {...objSindico}, {headers:{'x-access-token': token}});
  return resp.data;
};

const updateSindico = async (idSindico, objSindico, token) => {
    const resp = await axios.put(`${process.env.VUE_APP_URL_API}/sindico/${idSindico}`, {...objSindico}, {headers:{'x-access-token': token}});
    return resp.data;
  };

const addSindico = async (objSindico, token) => {
  const resp = await axios.post(`${process.env.VUE_APP_URL_API}/sindico/add`, {...objSindico}, {headers:{'x-access-token': token}});
    return resp.status;
};

const deleteSindico = async (idSindico, token) => {
  const resp = await axios.delete(`${process.env.VUE_APP_URL_API}/sindico/${idSindico}`, {headers:{'x-access-token': token}});
  return resp.status;
};

export { getSindicos, postSindico, updateSindico,  addSindico, deleteSindico};