<template>
  <loader-component v-if="isLoading"></loader-component>

  <side-navbar-component></side-navbar-component>  

  <div class="container-fluid">

    <div>
      <!-- Buttons -->
      <div class="row mx-0 mt-2">
        <div class="d-flex flex-row-reverse">
          <button type="button" class="btn btn-primary mt-2 corEngecyber" data-bs-toggle="modal" data-bs-target="#modalNewStaticBackdrop">
            Novo
          </button>
        </div>
      </div>
      <!-- END Buttons -->

      <!-- Modal New-->
      <div class="modal fade" id="modalNewStaticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalNewStaticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="modalNewStaticBackdropLabel">Cadastro de Usuários</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" v-on:click="limparDados(); removeAlert('modalNewStaticBackdrop')"></button>
            </div>
            <div class="modal-body">
              <div class="mb-12">
                <label for="inputNome" class="form-label">Nome</label>
                <input type="text" class="form-control" id="inputNome" v-model="nome">
              </div>
              <div class="mb-12">
                <label for="inputUsuario" class="form-label">Usuário</label>
                <input type="text" class="form-control" id="inputUsuario" v-model="usuario">
              </div>
              <div class="mb-12">
                <label for="inputSenha" class="form-label">Senha</label>
                <input type="password" class="form-control" id="inputSenha" v-model="senha">
              </div>
              <div class="mb-12">
                <label for="inputSenha2" class="form-label">Repita a senha</label>
                <input type="password" class="form-control" id="inputSenha2" v-model="senha2">
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" v-on:click="limparDados(); removeAlert('modalNewStaticBackdrop')">Cancelar</button>
              <button type="button" class="btn btn-primary" v-on:click="cadastrarUsuario(usuario, senha, senha2, nome)">Salvar</button>
            </div>
          </div>
        </div>
      </div>  
      <!-- END Modal New-->  

      <!-- Table -->
      <div class="row mx-0" >
        <div class="col-12">
          <div class="card mt-4">
            <div class="card-header py-1 bg-primary text-light fw-bold corEngecyber">
              Usuários Cadastrados
            </div>
            <div class="card-body" id="cardBodyTable">
              <div class="table-responsive">
                <table class="table table-bordered table-striped table-hover">
                  <thead>
                    <tr>
                      <th class="col text-center py-1">Usuário</th>
                      <th class="col text-center py-1">Nome</th>
                      <th class="col text-center py-1">Excluir</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="usuario in arrayUsuarios" v-bind:key="usuario.idUsu">
                      <td class="text-center align-middle py-1"> <small> {{usuario.usuarioUsu}} </small> </td>
                      <td class="text-center align-middle py-1"> <small> {{usuario.nomeUsu}} </small> </td>
                      <td class="text-center align-middle py-1">
                        <button v-if="usuario.idUsu === 1" type="button" class="btn btn-danger px-2 py-1" data-bs-toggle="modal" data-bs-target="#modalDelete" v-on:click="selecionarUsuario(usuario)" disabled>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3-fill" viewBox="0 0 16 16">
                            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z"/>
                          </svg>
                        </button>
                        <button v-else-if="usuario.idUsu !== 1" type="button" class="btn btn-danger px-2 py-1" data-bs-toggle="modal" data-bs-target="#modalDelete" v-on:click="selecionarUsuario(usuario)">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3-fill" viewBox="0 0 16 16">
                            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z"/>
                          </svg>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END Table -->

      <!-- Modal Delete -->
      <div class="modal fade" id="modalDelete" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalDeleteLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="modalDeleteLabel">Confirmar Exclusão</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" v-on:click="limparDados(); removeAlert('modalDelete'); removeDisableBtn()"></button>
            </div>
            <div class="modal-body">
              <p>
                Tem certeza que deseja excluir o usuário <span class="fw-bold"> {{usuario}} </span> ? <br /> Todos os dados serão excluídos.
              </p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" v-on:click="limparDados(); removeAlert('modalDelete'); removeDisableBtn()">Cancelar</button>
              <button type="button" class="btn btn-primary" id="btnDelete" v-on:click="excluirUsuario(id)">Excluir</button>
            </div>
          </div>
        </div>
      </div>
      <!-- END Modal Delete -->

    </div>

  </div>
</template>

<script>
import LoaderComponent from './LoaderComponent.vue';
import { tratamentoError } from '@/helpers/utils';
import { getUsuarios, postUsuario, deleteUsuario } from '@/service/usuarioService';

export default {
  components: { LoaderComponent },

  data: function(){
    return {
      isLoading: false,
      token: sessionStorage.getItem('token'),
      
      id: "",
      usuario:"",
      senha:"",
      senha2:"",
      nome:"",

      arrayUsuarios: []
    }
  },

  methods: {
    cadastrarUsuario: async function ($usuario, $senha, $senha2, $nome){
      if($usuario === "" || $senha === "" || $senha2 === "" || $nome === ""){
        this.mostrarAlert("modalNewStaticBackdrop", "Todos os campos devem ser preenchidos!", "alert-warning");
        return
      } 
      
      if($senha !== $senha2){
        this.mostrarAlert("modalNewStaticBackdrop", "Os Códigos de Acesso não coincidem!", "alert-warning");
        return
      }            
      
      try {
        let objUsuario = {usuario: $usuario, senha: $senha, nome: $nome}
        let resp = await postUsuario(objUsuario, this.token);

        if(resp !== 200){
          this.mostrarAlert("modalNewStaticBackdrop", "Houve um erro ao cadastrar!", "alert-danger");
          return
        }

        this.arrayUsuarios = await getUsuarios(this.token); 
        this.mostrarAlert("modalNewStaticBackdrop", "Usuário cadastrado com sucesso!", "alert-success");
        
      } catch (error) {
          const respError = tratamentoError(error);
          this.mostrarAlert("modalNewStaticBackdrop", respError, "alert-danger"); 

      } finally {
          setTimeout(() => {
            this.removeAlert("modalNewStaticBackdrop");
            this.limparDados();
          }, 2000)
      }
    },
    
    excluirUsuario: async function(id){
      try {
        let resp = await deleteUsuario(id, this.token);

        if(resp !== 200){
            this.mostrarAlert("modalDelete", "Houve um erro ao excluir!", "alert-danger");
            return
        }
        
        this.arrayUsuarios = await getUsuarios(this.token); 
        this.mostrarAlert("modalDelete", "Usuário excluído com sucesso!", "alert-success");
        this.disableBtn();
       
      } catch (error) {
        const respError = tratamentoError(error);
        this.mostrarAlert("modalDelete", respError, "alert-danger");  
      }
    },

    selecionarUsuario: function(usuario){
      this.id = usuario.idUsu;
      this.usuario = usuario.usuarioUsu;
    },

    limparDados:function(){
      this.usuario = "";
      this.senha = "";
      this.senha2 = "";
      this.nome = "";
    },
   
    mostrarAlert: function(idLocal, mensagem, tipoAlert){
      const local = document.getElementById(idLocal);      
      const isSectionAlert = local.getElementsByTagName('section')[0];

      if(isSectionAlert) local.removeChild(isSectionAlert);
      
      const sectionAlert = document.createElement('section');
      sectionAlert.setAttribute('id', 'alertId');
      sectionAlert.style.margin = "0px 20vw";
      sectionAlert.style.textAlign = "center";
      sectionAlert.style.fontWeight = "bold";

      sectionAlert.innerHTML = 
        `<div class="alert ${tipoAlert} alert-dismissible" role="alert">
          <p style="margin-bottom: 0">${mensagem}</p>
        </div>`;
          
      local.appendChild(sectionAlert);
    },
    
    removeAlert: function(idLocal){
      const local = document.getElementById(idLocal);

      if(local.getElementsByTagName('section').length === 0) return;

      const sectionAlert = local.getElementsByTagName('section')[0];

      if(sectionAlert) local.removeChild(sectionAlert);
    },
    
    disableBtn: function (){
      let btn = document.getElementById("btnDelete");
      btn.classList.add("disabled");
    },

    removeDisableBtn: function (){
      let btn = document.getElementById("btnDelete");

      if(btn.classList.contains("disabled")){
        btn.classList.remove("disabled");
      }
    }
  },

  async mounted(){
    try {
        this.isLoading = true;
        this.arrayUsuarios = await getUsuarios(this.token); 
    } catch (error) {
        this.mostrarAlert("cardBodyTable", "Houve um erro ao carregar as informações!", "alert-danger");      
        
        if(error.response.status === 401) {
          this.mostrarAlert("cardBodyTable", "Tempo de acesso finalizado.<br>Saia da aplicação e faça Login novamente!", "alert-danger");
          return
        }        
    } finally {
      this.isLoading = false;
    }
  },
 
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .container-fluid {
    padding-left: 65px;
  }

  .corEngecyber {
    background-color: #1271A9 !important;
  }

</style>
