import axios from "axios";

const getUsuarios = async (token) => {
  const resp = await axios.get(`${process.env.VUE_APP_URL_API}/usuario`, {headers:{'x-access-token': token}});
  return resp.data ;
};

const postUsuario = async (objUsuario, token) => {
  const resp = await axios.post(`${process.env.VUE_APP_URL_API}/usuario`, {...objUsuario}, {headers:{'x-access-token': token}});
  return resp.status;
};

const deleteUsuario = async (idUsuario, token) => {
  const resp = await axios.delete(`${process.env.VUE_APP_URL_API}/usuario/${idUsuario}`, {headers:{'x-access-token': token}});
  return resp.status;
};

export { getUsuarios, postUsuario, deleteUsuario };