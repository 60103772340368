<template>
    <div class="d-flex flex-row-reverse">
        <button type="button" class="btn btn-primary mt-1 mx-5 corEngecyber" v-on:click="voltar">
            Voltar
        </button>
        <button type="button" class="btn btn-primary mt-1 mx-5 corEngecyber" v-on:click="imprimir">
            Imprimir
        </button>
    </div>
    <div class="row mx-0" >
        <div class="col-12">
          <div class="card mt-1">
            <div class="card-header py-1 bg-primary text-light fw-bold text-center corEngecyber">
              ENGECYBER
            </div>
            <div class="card-body" id="cardBodyTable">
              <h3 class="py-1 fw-bold text-center bgGrey">Relatório Produtividade Técnica</h3>
              <h5 class="pt-2 pb-3 fw-bold text-center">Referente ao Período de {{ dataIni }} até {{ dataFim }}</h5>
              <div class="table-responsive">
                <table class="table table-bordered table-striped table-hover">
                  <thead>
                    <tr>
                      <th class="col text-center py-1 bgGrey">Profissional</th>
                      <th class="col text-center py-1 bgGrey">Cargo</th>
                      <th class="col text-center py-1 bgGrey">Quantidade</th>
                      <th class="col text-center py-1 bgGrey">Percentual</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for=" resultado in arrayResultados " v-bind:key="resultado.idTec">
                      <td class="text-center align-middle py-1"> {{ resultado.nomeTec }} </td>
                      <td class="text-center align-middle py-1"> 
                        <span v-if="resultado.cargoTec === 3" v-bind:id="resultado.idTec"> Técnico </span>
                        <span v-else-if="resultado.cargoTec === 4" v-bind:id="resultado.idTec"> Supervisor </span>
                        <span v-else-if="resultado.cargoTec === 5" v-bind:id="resultado.idTec"> Oficial </span>
                      </td>
                      <td class="text-center align-middle py-1"> {{ resultado.quantidade }} </td>
                      <td class="text-center align-middle py-1"> {{ (resultado.quantidade * 100 / this.totalChamadosFechados).toFixed(2) }}% </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <th class="col text-center py-1 bgGrey" colspan="8">
                        Total de Chamados Finalizados no Período: {{ this.totalChamadosFechados || 0 }}
                      </th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
export default {
  data: function(){
    return{
      dataIni:'',
      dataFim:'',
      arrayResultados: [],
      totalChamadosFechados:''
    }
  },

  mounted(){
    this.dataIni = JSON.parse(sessionStorage.getItem('dataIni'));
    this.dataFim = JSON.parse(sessionStorage.getItem('dataFim'));
    this.arrayResultados = JSON.parse(sessionStorage.getItem('resultadosRelatorio'));
    this.totalChamadosFechados = sessionStorage.getItem('totalChamadosFechados');
  },

  methods: {
    imprimir: function (){
        window.print();
    },

    voltar: function(){
        this.$router.back()
    }
  }
}
</script>

<style>
  .corEngecyber {
    background-color: #1271A9 !important;
  }

  .bgGrey {
    background-color: lightgrey !important;
  }
</style>