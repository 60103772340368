<template>
  <loader-component v-if="isLoading"></loader-component>

  <side-navbar-component></side-navbar-component>
  
  <div class="container-fluid">

    <div>

      <!-- Table -->
      <div class="row mx-0">
        <div class="col-12">
          <div class="card mt-4">
            <div class="card-header py-1 bg-primary text-light fw-bold corEngecyber">
              Consumo de Materiais
            </div>
            <div class="card-body" id="cardBodyTable">
              <div class="table-responsive">
                <table class="table table-bordered table-striped table-hover">
                  <thead>
                    <tr>
                      <th class="col-1 text-center py-1">Técnico</th>
                      <th class="col-2 text-center py-1">Residencial</th>
                      <th class="col-4 text-center py-1">Chamado</th>
                      <th class="col-1 text-center py-1"> <small> Dt. Finalização </small> </th>
                      <th class="col-2 text-center py-1">Material</th>
                      <th class="col-1 text-center py-1">Quant.</th>
                      <th class="col-1 text-center py-1">Editar</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="consumoMat in arrayConsumoMat" v-bind:key="consumoMat.idConsMat">
                      <td class="text-center align-middle py-1"> <small> {{ consumoMat.nomeTec }} </small> </td>
                      <td class="text-center align-middle py-1"> <small> {{ consumoMat.nomeRes }} </small> </td>
                      <td class="text-left align-middle py-1"> <small> {{ consumoMat.descricaoCha }} </small> </td>
                      <td class="text-center align-middle py-1"> <small> {{ this.formatarData(consumoMat.dataFinalizacaoCha) }} </small> </td>
                      <td class="text-center align-middle py-1"> <small> {{ consumoMat.descricaoMat }} </small> </td>
                      <td class="text-center align-middle py-1"> <small> {{ consumoMat.quantidadeConsMat }} </small> </td>
                      <td class="text-center align-middle py-1">
                        <button type="button" class="btn btn-warning px-2 py-1" data-bs-toggle="modal" data-bs-target="#modalEditStaticBackdrop" v-on:click="selecionarMaterial(consumoMat)">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-fill" viewBox="0 0 16 16">
                            <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                          </svg>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END Table -->

      <!-- Modal Edit-->
      <div class="modal fade" id="modalEditStaticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalEditStaticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="modalEditStaticBackdropLabel"> Ajuste de Consumo de Materiais</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" v-on:click="limparDados(); removeAlert('modalEditStaticBackdrop')"></button>
            </div>
            <div class="modal-body">
              <div class="mb-12">
                <label for="inputResidencial" class="form-label">Residencial</label>
                <input type="text" class="form-control" id="inputResidencial" disabled>
              </div>
              <div class="mb-12">
                <label for="inputChamado" class="form-label">Chamado</label>
                <input type="text" class="form-control" id="inputChamado" disabled>
              </div>
              <div class="mb-12">
                <label for="selectMaterial" class="form-label">Material</label>
                <select class="form-select" aria-label="lista-materiais" id="selectMaterial" v-model="material">
                  <option v-for=" material in arrayMaterial" v-bind:key="material.idMat" v-bind:value="material.descricaoMat"> {{ material.descricaoMat }} </option>
                </select>
              </div>
              <div class="mb-12">
                <label for="inputQuantidade" class="form-label">Quantidade</label>
                <input type="number" class="form-control" id="inputQuantidade" v-model="quantidade">
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" v-on:click="limparDados(); removeAlert('modalEditStaticBackdrop')">Cancelar</button>
              <button type="button" class="btn btn-primary" v-on:click="atualizarConsumoMaterial(id, material, quantidade)">Atualizar</button>
            </div>
          </div>
        </div>
      </div>  
      <!-- END Modal Edit--> 

    </div>

  </div>
</template>

<script>
import LoaderComponent from './LoaderComponent.vue';
import { getConsumoMateriais, updateConsumoMaterial } from '@/service/consumoMaterialService';
import { getMateriais } from '@/service/materialService';
import { tratamentoError, formatarDataTela } from '@/helpers/utils';

export default {
  components: { LoaderComponent },

  data: function(){
    return {
      isLoading: false,
      token: sessionStorage.getItem('token'),

      id: 0,
      material: "",
      quantidade: 0,

      arrayMaterial : [],
      arrayConsumoMat: []
    }
  },

  methods:{
    atualizarConsumoMaterial: async function(id, $material, $quantidade){
      if($material === "" || $quantidade === ""){
        this.mostrarAlert("modalEditStaticBackdrop", "Todos os campos devem ser preenchidos!", "alert-warning");
        return
      } 
        
      let idMaterial = this.arrayMaterial.find((itemArray) => {
        return itemArray.descricaoMat === $material
      })
      
      try {
        let objConMaterial = {material: idMaterial.idMat, quantidade: $quantidade}
        let resp = await updateConsumoMaterial(id, objConMaterial, this.token);

        if(resp !== 200){
          this.mostrarAlert("modalEditStaticBackdrop", "Houve um erro ao atualizar!", "alert-danger");
          return
        }

        this.arrayConsumoMat = await getConsumoMateriais(this.token);
        this.mostrarAlert("modalEditStaticBackdrop", "Consumo de Material atualizado com sucesso!", "alert-success");
        
      } catch (error) {
        const respError = tratamentoError(error);
        this.mostrarAlert("modalEditStaticBackdrop", respError, "alert-danger"); 
      }
    },

    selecionarMaterial: function(consumoMatEscolhido){
      this.id = consumoMatEscolhido.idConsMat;
      this.material = consumoMatEscolhido.descricaoMat;
      this.quantidade = consumoMatEscolhido.quantidadeConsMat;
    },

    limparDados:function(){
      this.material = "";
      this.quantidade = "";
    },

    mostrarAlert: function(idLocal, mensagem, tipoAlert){
      const local = document.getElementById(idLocal);      
      const isSectionAlert = local.getElementsByTagName('section')[0];

      if(isSectionAlert) local.removeChild(isSectionAlert);
      
      const sectionAlert = document.createElement('section');
      sectionAlert.setAttribute('id', 'alertId');
      sectionAlert.style.margin = "0px 20vw";
      sectionAlert.style.textAlign = "center";
      sectionAlert.style.fontWeight = "bold";

      sectionAlert.innerHTML = 
        `<div class="alert ${tipoAlert} alert-dismissible" role="alert">
          <p style="margin-bottom: 0">${mensagem}</p>
        </div>`;
          
      local.appendChild(sectionAlert);
    },
    
    removeAlert: function(idLocal){
      const local = document.getElementById(idLocal);

      if(local.getElementsByTagName('section').length === 0) return;

      const sectionAlert = local.getElementsByTagName('section')[0];

      if(sectionAlert) local.removeChild(sectionAlert);
    },

    formatarData: function (dt) {
      return formatarDataTela(dt);
    },
  },

  async mounted(){
    try {
        this.isLoading = true;
        this.arrayConsumoMat = await getConsumoMateriais(this.token);
        this.arrayMaterial = await getMateriais(this.token);
    } catch (error) {
        this.mostrarAlert("cardBodyTable", "Houve um erro ao carregar as informações!", "alert-danger");      
        
        if(error.response.status === 401) {
          this.mostrarAlert("cardBodyTable", "Tempo de acesso finalizado.<br>Saia da aplicação e faça Login novamente!", "alert-danger");
          return
        }    
    } finally {
        this.isLoading = false;
    }
  },
 
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .container-fluid {
    padding-left: 65px;
  }

  .corEngecyber {
    background-color: #1271A9 !important;
  }

</style>
