<template>
    <div class="d-flex flex-row-reverse">
        <button type="button" class="btn btn-primary mt-1 mx-5 corEngecyber" v-on:click="voltar">
            Voltar
        </button>
        <button type="button" class="btn btn-primary mt-1 mx-5 corEngecyber" v-on:click="imprimir">
            Imprimir
        </button>
    </div>
    <div class="row mx-0" >
        <div class="col-12">
            <div class="card mt-1">
                <div class="card-header py-1 bg-primary text-light fw-bold text-center corEngecyber">
                    ENGECYBER
                </div>
                <div class="card-body" id="cardBodyTable">
                    <h3 class="py-1 fw-bold text-center bgGrey">Relatório Total de Chamados por Período</h3>
                    <h5 class="pt-1 pb-2 fw-bold text-center">Referente ao Período de {{ dataIni }} até {{ dataFim }}</h5>
                    <div class="table-responsive">
                        <table class="table table-bordered table-striped table-hover">
                            <thead>
                            <tr>
                                <th class="col text-center py-1 bgGrey">Residencial</th>
                                <th class="col text-center py-1 bgGrey">Total de Chamados Abertos</th>
                                <th class="col text-center py-1 bgGrey">Total de Chamados Concluídos</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for=" resultado in arrayResultados " v-bind:key="resultado.idRes">
                                <td class="text-left align-middle py-1"> {{ resultado.nomeRes }} </td>
                                <td class="text-center align-middle py-1"> {{ resultado.chamadosAbertos || 0}} </td>
                                <td class="text-center align-middle py-1"> {{ resultado.chamadosFechados || 0}} </td>
                            </tr>
                            </tbody>
                            <tfoot>
                            <tr>
                                <th class="col text-center py-1 bgGrey">Total do Período</th>
                                <th class="col text-center py-1 bgGrey"> {{ totalChamadosAbertos || 0 }} </th>
                                <th class="col text-center py-1 bgGrey"> {{ totalChamadosFechados || 0 }} </th>
                            </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: function(){
        return {
            dataIni:'',
            dataFim:'',
            arrayResultados: [],
            totalChamadosAbertos:'',
            totalChamadosFechados:''
        }
    },

    mounted(){
        this.dataIni = JSON.parse(sessionStorage.getItem('dataIni'));
        this.dataFim = JSON.parse(sessionStorage.getItem('dataFim'));
        this.arrayResultados = JSON.parse(sessionStorage.getItem('resultadosRelatorio'));
        this.totalChamadosAbertos = sessionStorage.getItem('totalChamadosAbertos');
        this.totalChamadosFechados = sessionStorage.getItem('totalChamadosFechados');
    },

    methods: {
        imprimir: function (){
            window.print();
        },

        voltar: function(){
            this.$router.back()
        }
    }
}
</script>

<style>
  .corEngecyber {
    background-color: #1271A9 !important;
  }

  .bgGrey {
    background-color: lightgrey !important;
  }
</style>