<template>
  <loader-component v-if="isLoading"></loader-component>

  <side-navbar-component></side-navbar-component>
  
  <div class="container-fluid">
    <div>
      <!-- Card -->
      <div class="row mx-0">
        <div class="col-12" id="alertEnvio">
          <div class="card mt-4">
            <div class="card-header py-1 bg-primary text-light fw-bold corEngecyber">
              Envio de Relatório
            </div>

            <div class="card-body py-2">
              <div class="row">

                <div class="col-6">
                  <div class="mb-12">
                    <label for="selectResidencial" class="form-label fw-bold">Residencial</label>
                    <select class="form-select" aria-label="lista-residencial" id="selectResidencial" v-model="idResidencial">
                      <option v-for=" residencial in arrayResidencial" v-bind:key="residencial.idRes" v-bind:value="residencial.idRes"> {{ residencial.nomeRes }} </option>
                    </select>
                  </div>
                </div>

                <div class="col-6">
                  <div class="mb-12">
                    <label for="formFile" class="form-label fw-bold">Arquivo para Envio (Tamanho Máximo 6MB)</label>
                    <input class="form-control" type="file" id="formFile">
                  </div>
                </div>

              </div>

              <div class="row align-items-end">
                <div class="col-3">
                  <div class="mb-12">
                    <label for="inputDate" class="form-label fw-bold mt-3">Referente ao Mês</label>
                    <input type="month" class="form-control" id="inputDate" v-model="data">
                  </div>
                </div>

                <div class="col-2">
                  <button type="button" class="btn btn-primary px-4 corEngecyber" v-on:click="enviarRelatorio(idResidencial, data)">
                    Enviar
                  </button>
                </div>
            </div>
            
          </div>
          </div>
        </div>
      </div>
      <!-- END Card --> 

      <!-- Table -->
      <div class="row mx-0">
        <div class="col-12">
          <div class="card mt-4">
            <div class="card-header py-1 bg-primary text-light fw-bold corEngecyber">
              Relatórios Enviados
            </div>
            <div class="card-body py-2" id="cardBodyTable">
              <div class="table-responsive">
                <table class="table table-bordered table-striped table-hover">
                  <thead>
                    <tr>
                      <th class="col text-center py-1">Data de Referência</th>
                      <th class="col text-center py-1">Residencial</th>
                      <th class="col text-center py-1">Relatório</th>
                      <th class="col text-center py-1">Excluir</th>
      
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="relatorio in arrayRelatorio" v-bind:key="relatorio.idEnv">
                      <td class="text-center align-middle py-1"> {{ this.formatarData(relatorio.dataEnv) }} </td>
                      <td class="text-center align-middle py-1"> {{ relatorio.residencialEnv }} </td>
                      <td class="text-center align-middle py-1"> {{ relatorio.nomeEnv }} </td>
                      <td class="text-center align-middle py-1">
                        <button type="button" class="btn btn-danger px-2 py-1" data-bs-toggle="modal" data-bs-target="#modalDelete" v-on:click="selecionarRelatorio(relatorio)">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3-fill" viewBox="0 0 16 16">
                            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z"/>
                          </svg>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END Table -->

      <!-- Modal Delete -->
      <div class="modal fade" id="modalDelete" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalDeleteLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="modalDeleteLabel">Confirmar Exclusão</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" v-on:click="removeAlert('modalDelete'); removeDisableBtn()"></button>
            </div>
            <div class="modal-body">
              <p>
                Tem certeza que deseja excluir o relatório <br /> <span class="fw-bold"> {{nome}} </span> ?
              </p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" v-on:click="removeAlert('modalDelete'); removeDisableBtn()">Cancelar</button>
              <button type="button" class="btn btn-primary" id="btnDelete" v-on:click="excluirRelatorio(id)">Excluir</button>
            </div>
          </div>
        </div>
      </div>
      <!-- END Modal Delete -->

    </div>

  </div>
</template>

<script>
import LoaderComponent from './LoaderComponent.vue';
import { getRelatorios, postRelatorio, deleteRelatorio } from '@/service/envioRelatorioService';
import { getResidenciais } from '@/service/residencialService';
import { tratamentoError, formatarDataTela } from '@/helpers/utils';

export default {
  components: { LoaderComponent },

  data: function(){
    return {
      isLoading: false,
      token: sessionStorage.getItem('token'),
     
      id:"",
      idResidencial:"",
      nome:"",
      data:"",

      arrayResidencial: [],
      arrayRelatorio: []
    }
  },

  methods: {
    enviarRelatorio: async function(idResidencial, data){
      if(idResidencial == "" || data == ""){
        this.mostrarAlert("alertEnvio", "Todos os campos devem ser preenchidos!", "alert-warning");

        setTimeout(() => {
          this.removeAlert("alertEnvio");
        }, 2000);

        return        
      } 
        
      try {
        let objEnvRelatorio = {residencial: idResidencial, nome: this.nome, data: data}
        let resp = await postRelatorio(objEnvRelatorio, this.token);

        if(resp !== 200){
          this.mostrarAlert("alertEnvio", "Houve um erro ao enviar!", "alert-danger");
          return
        }

        this.mostrarAlert("alertEnvio", "Relatório enviado com sucesso!", "alert-success");

      } catch (error) {
          const respError = tratamentoError(error);
          this.mostrarAlert("alertEnvio", respError, "alert-danger"); 

      } finally {
          setTimeout(() => {
            this.removeAlert("alertEnvio");
            this.limparDados();
          }, 2000);
      }  
    },

    excluirRelatorio: async function(idRelatorio){
      try {
        let resp = await deleteRelatorio(idRelatorio, this.token);

        if(resp !== 200){
          this.mostrarAlert("modalDelete", "Houve um erro ao excluir!", "alert-danger");
          return
        }

        this.arrayRelatorio = await getRelatorios(this.token);  
        this.mostrarAlert("modalDelete", "Relatório excluído com sucesso!", "alert-success");
        this.disableBtn();
        
      } catch (error) {
        const respError = tratamentoError(error);
        this.mostrarAlert("modalDelete", respError, "alert-danger"); 
      }
    },

    limparDados:function(){
      this.id = "",
      this.idResidencial= "";
      this.nome = "",
      this.data = "";
    },

    selecionarRelatorio: function(relatorioSelecionado){
      this.id = relatorioSelecionado.idEnv;
      this.nome = relatorioSelecionado.nomeEnv;
    },

    mostrarAlert: function(idLocal, mensagem, tipoAlert){
      const local = document.getElementById(idLocal);      
      const isSectionAlert = local.getElementsByTagName('section')[0];

      if(isSectionAlert) local.removeChild(isSectionAlert);
      
      const sectionAlert = document.createElement('section');
      sectionAlert.setAttribute('id', 'alertId');
      sectionAlert.style.margin = "2vh 20vw 0vh";
      sectionAlert.style.textAlign = "center";
      sectionAlert.style.fontWeight = "bold";

      sectionAlert.innerHTML = 
        `<div class="alert ${tipoAlert} alert-dismissible" role="alert">
          <p style="margin-bottom: 0">${mensagem}</p>
        </div>`;
          
      local.appendChild(sectionAlert);
    },
    
    removeAlert: function(idLocal){
      const local = document.getElementById(idLocal);

      if(local.getElementsByTagName('section').length === 0) return;

      const sectionAlert = local.getElementsByTagName('section')[0];

      if(sectionAlert) local.removeChild(sectionAlert);
    }, 

    formatarData: function (dt) {
      return formatarDataTela(dt);
    },

    disableBtn: function (){
      let btn = document.getElementById("btnDelete");
      btn.classList.add("disabled");
    },

    removeDisableBtn: function (){
      let btn = document.getElementById("btnDelete");

      if(btn.classList.contains("disabled")){
        btn.classList.remove("disabled");
      }
    }
  },

  async mounted(){
    try {
        this.isLoading = true;
        this.arrayRelatorio = await getRelatorios(this.token);   
        this.arrayResidencial = await getResidenciais(this.token);
    } catch (error) {
        this.mostrarAlert("cardBodyTable", "Houve um erro ao carregar as informações!", "alert-danger");      
        
        if(error.response.status === 401) {
          this.mostrarAlert("cardBodyTable", "Tempo de acesso finalizado.<br>Saia da aplicação e faça Login novamente!", "alert-danger");
          return
        }     
    } finally {
        this.isLoading = false;
    }
  },
 
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .container-fluid {
    padding-left: 65px;
  }

  .corEngecyber {
    background-color: #1271A9 !important;
  }

</style>
