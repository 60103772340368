<template>
    <loader-component v-if="isLoading"></loader-component>

    <side-navbar-component></side-navbar-component>

    <div class="container-fluid">
        <div>
            <!-- Buttons -->
            <div class="row mx-0 mt-2">
                <div class="col-12">
                    <div class="d-flex flex-row-reverse">
                        <button type="button" class="btn btn-primary mt-2 corEngecyber" data-bs-toggle="modal" data-bs-target="#modalNewStaticBackdrop">
                            Novo
                        </button>
                    </div>
                </div>
            </div>
            <!-- END Buttons -->

            <!-- Modal New-->
            <div class="modal fade" id="modalNewStaticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalNewStaticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalNewStaticBackdropLabel">Cadastro de Síndico</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" v-on:click="limparDados(); removeAlert('modalNewStaticBackdrop')"></button>
                </div>
                <div class="modal-body">
                    <div class="mb-12">
                    <label for="inputNomeSin" class="form-label">Nome</label>
                    <input type="text" class="form-control" id="inputNomeSin" v-model="nome">
                    </div>
                    <div class="mb-12">
                    <label for="inputTelefoneSin" class="form-label">Telefone</label>
                    <input type="text" class="form-control" id="inputTelefoneSin" v-model="telefone">
                    </div>
                    <div class="mb-12">
                        <label for="inputCodigoSin" class="form-label">Código de Acesso</label>
                        <input type="number" class="form-control" id="inputCodigoSin" v-model="codigo">
                    </div>
                    <div class="mb-12">
                        <label for="selectTipoSin" class="form-label">Tipo de Síndico</label>
                        <select class="form-select" aria-label="tipos-sindico" id="selectTipoSin" v-model="tipo">
                        <option value="2">Síndico</option>
                        <option value="9">Zelador</option>
                        </select>
                    </div>
                    <div class="mb-12">
                        <label for="selectResidencialSin" class="form-label">Residencial</label>
                        <select class="form-select" aria-label="lista-residenciais" id="selectResidencialSin" v-model="residencial">
                            <option v-for=" residencial in arrayResidencial" v-bind:key="residencial.idRes" v-bind:value="residencial.idRes"> {{ residencial.nomeRes }} </option>
                        </select>
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" v-on:click="limparDados(); removeAlert('modalNewStaticBackdrop')">Cancelar</button>
                    <button type="button" class="btn btn-primary" v-on:click="cadastrarSindico(nome, telefone, codigo, tipo, residencial)">Salvar</button>
                </div>
                </div>
            </div>
            </div>  
            <!-- END Modal New-->

            <!-- Table -->
            <div class="row mx-0">
            <div class="col-12">
                <div class="card mt-4">
                <div class="card-header py-1 bg-primary text-light fw-bold corEngecyber">
                    Síndicos Cadastrados
                </div>
                <div class="card-body" id="cardBodyTable">
                    <div class="table-responsive">
                    <table class="table table-bordered table-striped table-hover">
                        <thead>
                        <tr>
                            <th class="col text-center py-1">Acesso</th>
                            <th class="col text-center py-1">Tipo</th>
                            <th class="col text-center py-1">Nome</th>
                            <th class="col text-center py-1">Telefone</th>
                            <th class="col text-center py-1">Residencial</th>
                            <th class="col text-center py-1">Add</th>
                            <th class="col text-center py-1">Alterar</th>
                            <th class="col text-center py-1">Excluir</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="sindico in arraySindico" v-bind:key="sindico.idSin">
                            <td class="text-center align-middle py-1"> <small> {{ sindico.codigoSin }} </small> </td>
                            <td class="text-center align-middle py-1"> <small> {{ sindico.tipoSin == 2 ? "Síndico" : "Zelador" }} </small> </td>
                            <td class="text-center align-middle py-1"> <small> {{ sindico.nomeSin }} </small> </td>
                            <td class="text-center align-middle py-1"> <small> {{ this.formatarTelelefone(sindico.telefoneSin) }} </small> </td>
                            <td class="text-left align-middle py-1"> <small> {{ sindico.nomeRes }} </small> </td>
                            <td class="text-center align-middle py-1">
                                <button type="button" class="btn btn-primary px-2 py-1" data-bs-toggle="modal" data-bs-target="#modalAddStaticBackdrop" v-on:click="selecionarSindicoAddResidencial(sindico)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
                                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                    </svg>
                                </button>
                            </td>
                            <td class="text-center align-middle py-1">
                                <button type="button" class="btn btn-warning px-2 py-1" data-bs-toggle="modal" data-bs-target="#modalEditStaticBackdrop" v-on:click="selecionarSindico(sindico)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-fill" viewBox="0 0 16 16">
                                    <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                                    </svg>
                                </button>
                            </td>
                            <td class="text-center align-middle py-1">
                                <button type="button" class="btn btn-danger px-2 py-1" data-bs-toggle="modal" data-bs-target="#modalDelete" v-on:click="selecionarSindico(sindico)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3-fill" viewBox="0 0 16 16">
                                    <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z"/>
                                    </svg>
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <!-- END Table -->

            <!-- Modal Add-->
            <div class="modal fade" id="modalAddStaticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalAddStaticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="modalAddStaticBackdropLabel">Inclusão de Residencial</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" v-on:click="limparDados(); removeAlert('modalAddStaticBackdrop')"></button>
                    </div>

                    <div class="modal-body">
                        <div class="mb-12">
                            <label for="selectResidencialSin" class="form-label">Residencial</label>
                            <select class="form-select" aria-label="lista-residenciais" id="selectResidencialSin" v-model="residencial">
                                <option v-for=" residencial in arrayResidencialFiltrado" v-bind:key="residencial.idRes" v-bind:value="residencial.idRes"> {{ residencial.nomeRes }} </option>
                            </select>
                        </div>
                    </div>
                    
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" v-on:click="limparDados(); removeAlert('modalAddStaticBackdrop')">Cancelar</button>
                        <button type="button" class="btn btn-primary" v-on:click="incluirResidencial(residencial)">Incluir</button>
                    </div>
                </div>
            </div>
            </div>  
            <!-- END Modal Add--> 
            
            <!-- Modal Edit-->
            <div class="modal fade" id="modalEditStaticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalEditStaticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="modalEditStaticBackdropLabel">Edição de Síndico</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" v-on:click="limparDados(); removeAlert('modalEditStaticBackdrop')"></button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-12">
                        <label for="inputNomeSin" class="form-label">Nome</label>
                        <input type="text" class="form-control" id="inputNomeSin" v-model="nome">
                        </div>
                        <div class="mb-12">
                        <label for="inputTelefoneSin" class="form-label">Telefone</label>
                        <input type="text" class="form-control" id="inputTelefoneSin" v-model="telefone">
                        </div>
                        <div class="mb-12">
                            <label for="inputCodigoSin" class="form-label">Código de Acesso</label>
                            <input type="number" class="form-control" id="inputCodigoSin" v-model="codigo">
                        </div>
                        <div class="mb-12">
                            <label for="selectTipoSin" class="form-label">Tipo de Síndico</label>
                            <select class="form-select" aria-label="tipos-sindico" id="selectTipoSin" v-model="tipo">
                                <option value="2">Síndico</option>
                                <option value="9">Zelador</option>
                            </select>
                        </div>
                        <div class="mb-12">
                            <label for="selectResidencialSin" class="form-label">Residencial</label>
                            <select class="form-select" aria-label="lista-residenciais" id="selectResidencialSin" v-model="residencial">
                                <option v-for="residencial in arrayResidencial" v-bind:key="residencial.idRes" v-bind:value="residencial.nomeRes"> {{ residencial.nomeRes }} </option>
                            </select>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" v-on:click="limparDados(); removeAlert('modalEditStaticBackdrop')">Cancelar</button>
                        <button type="button" class="btn btn-primary" v-on:click="atualizarSindico(id, nome, telefone, codigo, tipo, residencial)">Atualizar</button>
                    </div>
                    </div>
                </div>
            </div>  
            <!-- END Modal Edit--> 

            <!-- Modal Delete -->
            <div class="modal fade" id="modalDelete" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalDeleteLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalDeleteLabel">Confirmar Exclusão</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" v-on:click="limparDados(); removeAlert('modalDelete'); removeDisableBtn()"></button>
                </div>
                <div class="modal-body">
                    <p>
                    Tem certeza que deseja excluir o síndico <span class="fw-bold"> {{nome}} </span> ? <br /> Todos os dados serão excluídos.
                    </p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" v-on:click="limparDados(); removeAlert('modalDelete'); removeDisableBtn()">Cancelar</button>
                    <button type="button" class="btn btn-primary" id="btnDelete" v-on:click="excluirSindico(id)">Excluir</button>
                </div>
                </div>
            </div>
            </div>
            <!-- END Modal Delete -->
            
        </div>


    </div>
</template>

<script>
import LoaderComponent from './LoaderComponent.vue';
import { tratamentoError, formatarTelefoneTela } from '@/helpers/utils';
import { getSindicos, postSindico, updateSindico, addSindico, deleteSindico } from '@/service/sindicoService';
import { getResidenciais } from '@/service/residencialService';

export default {
  components: { LoaderComponent },

    data: function(){
        return {
            isLoading: false,
            token: sessionStorage.getItem('token'),

            id: 0,
            idSinRes: 0,
            nome:"",
            telefone:"",
            codigo: 0,
            tipo:"",
            residencial:"",
            arrayResidencialFiltrado: [],

            arrayResidencial: [],
            arraySindico: []
        }
    },

    methods:{
        cadastrarSindico: async function($nome, $telefone, $codigo, $tipo, $idResidencial){
            if($nome == "" || $telefone == "" || $codigo == "" || $tipo == "" || $idResidencial == ""){
                this.mostrarAlert("modalNewStaticBackdrop", "Todos os campos devem ser preenchidos!", "alert-warning");
                return
            } 
                
            try {
                let objSindico = {nome: $nome, telefone: $telefone, codigo: $codigo, tipo: $tipo, residencial: $idResidencial};
                let resp = await postSindico(objSindico, this.token);

                if(resp.codigoEmUso === true){
                    this.mostrarAlert("modalNewStaticBackdrop", "O Código de Acesso informado já está em uso!", "alert-danger");
                    return
                }

                this.arraySindico = await getSindicos(this.token);
                this.mostrarAlert("modalNewStaticBackdrop", "Síndico cadastrado com sucesso!", "alert-success");
                
            } catch (error) {
                const respError = tratamentoError(error);
                this.mostrarAlert("modalNewStaticBackdrop", respError, "alert-danger");
            
            } finally {
                setTimeout(() => {
                    this.removeAlert("modalNewStaticBackdrop");
                    this.limparDados();
                }, 2000)
            }
        },

        incluirResidencial: async function($idResidencial){
            if($idResidencial == ""){
                this.mostrarAlert("modalAddStaticBackdrop", "Selecione um Residencial!", "alert-warning");
                return
            }
                
            try {
                let objSindico = {nome: this.nome,tipo: this.tipo, telefone: this.telefone, codigo: this.codigo, residencial: $idResidencial};
                let resp = await addSindico(objSindico, this.token);

                if(resp !== 200){
                    this.mostrarAlert("modalAddStaticBackdrop", "Houve um erro ao incluir!", "alert-danger");
                    return
                }

                this.arraySindico = await getSindicos(this.token);
                this.mostrarAlert("modalAddStaticBackdrop", "Residencial incluído!", "alert-success");

                setTimeout(() => {
                    this.removeAlert("modalAddStaticBackdrop");
                    this.limparDados();
                }, 2000)
                
            } catch (error) {
                const respError = tratamentoError(error);
                this.mostrarAlert("modalAddStaticBackdrop", respError, "alert-danger");                 
            }            
        },

        atualizarSindico: async function(id, $nome, $telefone, $codigo, $tipo, $residencial){
            if($nome == "" || $telefone == "" || $codigo == "" || $tipo == "" || $residencial == ""){
                this.mostrarAlert("modalEditStaticBackdrop", "Todos os campos devem ser preenchidos!", "alert-warning");
                return
            } 

            let idResidencial = this.arrayResidencial.find((itemArray) => {
                return itemArray.nomeRes === $residencial
            })
            
            try {
                let objSindico = {nome: $nome, telefone: $telefone, codigo: $codigo, tipo: $tipo, residencial: idResidencial.idRes};
                let resp = await updateSindico(id, objSindico, this.token);

                if(resp.codigoEmUso === true){
                    this.mostrarAlert("modalEditStaticBackdrop", "O Código de Acesso informado já está em uso!", "alert-danger");
                    return
                }

                this.arraySindico = await getSindicos(this.token);
                this.mostrarAlert("modalEditStaticBackdrop", "Síndico atualizado com sucesso!", "alert-success");

            } catch (error) {
                const respError = tratamentoError(error);
                this.mostrarAlert("modalEditStaticBackdrop", respError, "alert-danger");  
            }            
        },

        excluirSindico: async function(id){
            try {
                let resp = await deleteSindico(id, this.token);

                if(resp !== 200){
                    this.mostrarAlert("modalDelete", "Houve um erro ao excluir!", "alert-danger");
                    return
                }

                this.arraySindico = await getSindicos(this.token);
                this.mostrarAlert("modalDelete", "Síndico excluído com sucesso!", "alert-success");
                this.disableBtn();
                
            } catch (error) {
                const respError = tratamentoError(error);
                this.mostrarAlert("modalDelete", respError, "alert-danger");  
            }
        },

        selecionarSindico: function(sindicoSelecionado){
            this.id = sindicoSelecionado.idSin;
            this.idSinRes = sindicoSelecionado.idSinRes;
            this.nome = sindicoSelecionado.nomeSin;
            this.telefone = this.formatarTelelefone(sindicoSelecionado.telefoneSin);
            this.codigo = sindicoSelecionado.codigoSin;
            this.tipo = sindicoSelecionado.tipoSin;
            this.residencial = sindicoSelecionado.nomeRes;           
        },

        selecionarSindicoAddResidencial: function(sindicoSelecionado){
            this.id = sindicoSelecionado.idSin;
            this.nome = sindicoSelecionado.nomeSin;
            this.tipo = sindicoSelecionado.tipoSin;
            this.telefone = sindicoSelecionado.telefoneSin;
            this.codigo = sindicoSelecionado.codigoSin;
            this.residencial = sindicoSelecionado.nomeRes;

            let idResidencial = this.arrayResidencial.find((itemArray) => {
                return itemArray.nomeRes === sindicoSelecionado.nomeRes
            })

            this.arrayResidencialFiltrado = this.arrayResidencial.filter((itemArray) => {
                return itemArray.idRes !== idResidencial.idRes
            })
        },

        formatarTelelefone: function(telefone){
            return formatarTelefoneTela(telefone)
        },

        limparDados:function(){
            this.nome = "";
            this.telefone = "";
            this.codigo = "";
            this.tipo = "";
            this.residencial = "";
        },

        mostrarAlert: function(idLocal, mensagem, tipoAlert){
            const local = document.getElementById(idLocal);      
            const isSectionAlert = local.getElementsByTagName('section')[0];

            if(isSectionAlert) local.removeChild(isSectionAlert);
            
            const sectionAlert = document.createElement('section');
            sectionAlert.setAttribute('id', 'alertId');
            sectionAlert.style.margin = "0px 20vw";
            sectionAlert.style.textAlign = "center";
            sectionAlert.style.fontWeight = "bold";

            sectionAlert.innerHTML = 
                `<div class="alert ${tipoAlert} alert-dismissible" role="alert">
                <p style="margin-bottom: 0">${mensagem}</p>
                </div>`;
                
            local.appendChild(sectionAlert);
        },
    
        removeAlert: function(idLocal){
            const local = document.getElementById(idLocal);

            if(local.getElementsByTagName('section').length === 0) return;

            const sectionAlert = local.getElementsByTagName('section')[0];

            if(sectionAlert) local.removeChild(sectionAlert);
        }, 

        disableBtn: function (){
            let btn = document.getElementById("btnDelete");
            btn.classList.add("disabled");
        },

        removeDisableBtn: function (){
            let btn = document.getElementById("btnDelete");

            if(btn.classList.contains("disabled")){
                btn.classList.remove("disabled");
            }
        }
    },

    async mounted(){
        try {
            this.isLoading = true;
            this.arraySindico = await getSindicos(this.token);    
            this.arrayResidencial = await getResidenciais(this.token);
        } catch (error) {
            this.mostrarAlert("cardBodyTable", "Houve um erro ao carregar as informações!", "alert-danger");      
        
            if(error.response.status === 401) {
            this.mostrarAlert("cardBodyTable", "Tempo de acesso finalizado.<br>Saia da aplicação e faça Login novamente!", "alert-danger");
            return
            }
        } finally {
            this.isLoading = false;
        }
    },
}
</script>

<style scoped>
  .container-fluid {
    padding-left: 65px;
  }

  .corEngecyber {
    background-color: #1271A9 !important;
  }
</style>
