import { format, subMonths, subDays } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

function tratamentoError(error){
    if(error.message === "Network Error"){
        return "Falha ocorrida na conexão com a API!";
    }

    if(error.response.status === 400) {
        return "Houve um erro. Contacte o desenvolvedor!"
    }         

    if(error.response.status === 401) {
        return "Tempo de acesso finalizado.<br>Saia da aplicação e faça Login novamente!"
    } 

    return "Erro inesperado. Contacte o desenvolvedor!"
}

function formatarDataTela(dt){
  if(dt === null){
      return "";
  }

  let ano = dt.slice(0, 4);
  let mes = dt.slice(5, 7);
  let dia = dt.slice(8, 10);
  let hora = dt.slice(11, 13);
  let minutos = dt.slice(14, 16);
  
  let novaData = new Date(ano, mes, dia, hora, minutos);
  novaData = subMonths(novaData, 1);
  
  if(mes === '01' && dia === '29') novaData = subDays(novaData, 3)
  if(mes === '01' && dia === '30') novaData = subDays(novaData, 3)
  if(mes === '01' && dia === '31') novaData = subDays(novaData, 3)
  
  return format(novaData, 'dd/MM/yyyy H:mm', {locale: ptBR})
}

// function formatarDataTela(dt){
//   if(dt === null){
//       return "";
//   }

//   let ano = dt.slice(0, 4);
//   let mes = dt.slice(5, 7);
//   let dia = dt.slice(8, 10);
//   let hora = dt.slice(11, 13);
//   let minutos = dt.slice(14, 16);
  
//   let novaData = subHours(new Date(ano, mes, dia, hora, minutos), 3);
//   novaData = subMonths(novaData, 1);
  
//   if(mes === '01' && dia === '29') novaData = subDays(novaData, 3)
//   if(mes === '01' && dia === '30') novaData = subDays(novaData, 3)
//   if(mes === '01' && dia === '31') novaData = subDays(novaData, 3)
  
//   return format(novaData, 'dd/MM/yyyy H:mm', {locale: ptBR})
// }

function formatarDataResidencial(dt, local){
    if(dt === null){
      return "";
    }

    let ano = dt.slice(0, 4);
    let mes = dt.slice(5, 7);
    let dia = dt.slice(8, 10);

    let novaData = new Date(ano, mes, dia);
    novaData = subMonths(novaData, 1);

    if(mes === '01' && dia === '29') novaData = subDays(novaData, 3)
    if(mes === '01' && dia === '30') novaData = subDays(novaData, 3)
    if(mes === '01' && dia === '31') novaData = subDays(novaData, 3)

    if(local === 'BR'){
    return format(novaData, 'dd/MM/yyyy', {locale: ptBR})
    } else  if(local === 'EUA'){
    return format(novaData, 'yyyy-MM-dd')
    }
}

function formatarDataParaBD(dt){
  return format(dt, 'yyyy-MM-dd HH:mm')
}

function formatarTelefoneTela(oldPhone){
    let newPhone = '';
    
    if(oldPhone.length === 14 || oldPhone.length === 15 || oldPhone.length === 16) newPhone = oldPhone;

    if (oldPhone.length === 11) {
        newPhone = oldPhone.replace(/\D/g, '').replace(
          /(\d{2})(\d)(\d{4})(\d{4})$/,
          '($1) $2 $3-$4'
        );
        
      } else if (oldPhone.length === 10) {
        newPhone = oldPhone.replace(/\D/g, '').replace(
          /(\d{2})(\d{4})(\d{4})$/,
          '($1) 9 $2-$3'
        );
        
      } else if (oldPhone.length === 9) {
        newPhone = oldPhone.replace(/\D/g, '').replace(
          /(\d)(\d{4})(\d{4})$/,
          '(31) $1 $2-$3'
        );
        
      } else if (oldPhone.length === 8) {
        newPhone = oldPhone.replace(/\D/g, '').replace(
          /(\d{4})(\d{4})$/,
          '(31) 9 $1-$2'
        );
      }
    
      return newPhone;
}

function formatarCpf(oldCpf){
    if (oldCpf.length === 11) {
        let newCpf = oldCpf.replace(/\D/g, '').replace(
          /(\d{3})(\d{3})(\d{3})(\d{2})$/,
          '$1.$2.$3-$4'
        );
    return newCpf;
    }
}

function formatarCnpj(oldCnpj){
    if (oldCnpj.length === 14) {
        let newCnpj = oldCnpj.replace(/\D/g, '').replace(
          /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
          '$1.$2.$3/$4-$5'
        );
        return newCnpj;
      }
    return 
}

function formatarCep(oldCep){
    if (oldCep.length === 8) {
        let newCep = oldCep.replace(/\D/g, '').replace(
          /(\d{5})(\d{3})$/,
          '$1-$2'
        );
       return newCep;
    }
}

export { tratamentoError, formatarDataTela, formatarDataResidencial, formatarDataParaBD, formatarTelefoneTela, formatarCpf, formatarCnpj, formatarCep };