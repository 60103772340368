import axios from "axios";

const getAtividades = async (token) => {
  const resp = await axios.get(`${process.env.VUE_APP_URL_API}/atividade`, {headers:{'x-access-token': token}});
  return resp.data;
};

const postAtividade = async (objAtividade, token) => {
  const resp = await axios.post(`${process.env.VUE_APP_URL_API}/atividade`, {...objAtividade}, {headers:{'x-access-token': token}});
  return resp.status;
};

const updateAtividade = async (idAtividade, objAtividade, token) => {
  const resp = await axios.put(`${process.env.VUE_APP_URL_API}/atividade/${idAtividade}`, {...objAtividade}, {headers:{'x-access-token': token}});
  return resp.status;
};

const deleteAtividade = async (idAtividade, token) => {
  const resp = await axios.delete(`${process.env.VUE_APP_URL_API}/atividade/${idAtividade}`, {headers:{'x-access-token': token}});
  return resp.status;
};

const getAreas = async (token) => {
  const resp = await axios.get(`${process.env.VUE_APP_URL_API}/atividade/area`, {headers:{'x-access-token': token}});
  return resp.data;
};

const postArea = async (area, token) => {
  const resp = await axios.post(`${process.env.VUE_APP_URL_API}/atividade/area`, {area}, {headers:{'x-access-token': token}});
  return resp.status;
};

const getPeriodicidades = async (token) => {
  const resp = await axios.get(`${process.env.VUE_APP_URL_API}/atividade/periodicidade`, {headers:{'x-access-token': token}});
  return resp.data;
};

export { getAtividades, postAtividade, updateAtividade,  deleteAtividade, getAreas, postArea, getPeriodicidades};