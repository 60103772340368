<template>
  <loader-component v-if="isLoading"></loader-component>

  <side-navbar-component></side-navbar-component>
  
  <div class="container-fluid">
    <div>

      <!-- Card -->
      <div class="row mx-0">
        <div class="col-12 col-sm-11 col-md-12" id="alertChamadosAbertos">
          <div class="card mt-2" style="margin-bottom: 2vh">
            <div class="card-header py-1 bg-primary text-light fw-bold corEngecyber">
              Pesquisa:
            </div>
            <div class="col-12">
              <div class="card-body py-2">
                <div class="d-flex justify-content-center align-items-center">
                  <label for="selectResidencial" class="form-label fw-bold">Residencial</label>
                  <select class="form-select mx-3" aria-label="lista-residencial" id="selectResidencial" v-model="idResidencial">
                    <option value="0"> -- Todos -- </option>
                    <option v-for=" residencial in arrayResidencial" v-bind:key="residencial.idRes" v-bind:value="residencial.idRes" > {{ residencial.nomeRes }} </option>
                  </select>
                  <label for="selectStatus" class="form-label fw-bold">Status</label>
                  <select class="form-select mx-3" aria-label="lista-status" id="selectStatus" v-model="status">
                    <option value="Todos"> -- Todos -- </option>
                    <option value="Aberto"> Aberto </option>
                    <option value="Andamento"> Andamento </option>
                    <option value="Finalizado"> Finalizado </option>
                    <option value="AbertoAndamento"> Aberto e em Andamento </option>
                  </select>
                  <button type="button" class="btn btn-primary mt-0 corEngecyber" v-on:click="pesquisarChamado(idResidencial, status)">
                    Pesquisar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END Card --> 

      <!-- Table -->
      <div class="row mx-0">
        <div class="col-12">
          <div class="card mt-4">
            <div class="card-header py-1 bg-primary text-light fw-bold corEngecyber">
              Todos os Chamados
            </div>
            <div class="card-body py-2" id="cardBodyTable">
              <div class="table-responsive">
                <table class="table table-bordered table-striped table-hover">
                  <thead>
                    <tr>
                      <th class="col text-center py-1">Descrição</th>
                      <th class="col text-center py-1">Nome</th>
                      <th class="col text-center py-1">Cargo</th>
                      <th class="col text-center py-1">Residencial</th>
                      <th class="col text-center py-1">Dt.Abertura</th>
                      <th class="col text-center py-1">Dt.Finalização</th>
                      <th class="col text-center py-1">Concluinte</th>
                      <th class="col text-center py-1">Status</th>
                      <th class="col-2 text-center py-1">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="chamado in arrayChamados" v-bind:key="chamado.idCha">
                      <td class="text-center align-middle py-1"> <small> {{ chamado.descricaoCha }} </small> </td>
                      <td class="text-center align-middle py-1"> <small> {{ chamado.solicitanteCha }} </small> </td>
                      <td class="text-center align-middle py-1"> 
                        <small> 
                          <span v-if="chamado.cargoCha === 3" v-bind:id="chamado.idCha"> Técnico </span>  
                          <span v-else-if="chamado.cargoCha === 4" v-bind:id="chamado.idCha"> Supervisor </span> 
                          <span v-else-if="chamado.cargoCha === 5" v-bind:id="chamado.idCha"> Oficial </span>   
                          <span v-else-if="chamado.cargoCha === 2" v-bind:id="chamado.idCha"> Síndico </span>   
                          <span v-else-if="chamado.cargoCha === 9" v-bind:id="chamado.idCha"> Zelador </span>   
                        </small>
                      </td>                      
                      <td class="text-center align-middle py-1"> <small> {{ chamado.nomeRes }}  </small> </td>
                      <td class="text-center align-middle py-1"> <small> {{ this.formatarData(chamado.dataAberturaCha) }} </small> </td>
                      <td class="text-center align-middle py-1"> <small> {{ this.formatarData(chamado.dataFinalizacaoCha) }} </small> </td>
                      <td class="text-center align-middle py-1"> <small> {{ chamado.nomeTec }} </small> </td>                      
                      <td class="text-center align-middle py-1">
                        <span v-if="chamado.statusCha === 0" class="badge text-bg-danger" v-bind:id="chamado.idCha"> Aberto </span>
                        <span v-else-if="chamado.statusCha === 2" class="badge text-bg-warning" v-bind:id="chamado.idCha"> Andamento </span>
                        <span v-else-if="chamado.statusCha === 1" class="badge text-bg-success" v-bind:id="chamado.idCha"> Finalizado </span>
                      </td>
                      <td class="text-center align-middle py-1">
                        <button type="button" class="btn btn-info mx-1 px-1 py-1" data-bs-toggle="modal" data-bs-target="#modalDetalhesStaticBackdrop" v-on:click="selecionarChamado(chamado); selecionarMateriaisConsumidos(chamado.idCha)">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                            <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                            <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                          </svg>
                        </button>
                        <button type="button" class="btn btn-warning mx-1 px-1 py-1" >
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-hammer" viewBox="0 0 16 16" v-on:click="atenderChamado(chamado)">
                            <path d="M9.972 2.508a.5.5 0 0 0-.16-.556l-.178-.129a5.009 5.009 0 0 0-2.076-.783C6.215.862 4.504 1.229 2.84 3.133H1.786a.5.5 0 0 0-.354.147L.146 4.567a.5.5 0 0 0 0 .706l2.571 2.579a.5.5 0 0 0 .708 0l1.286-1.29a.5.5 0 0 0 .146-.353V5.57l8.387 8.873A.5.5 0 0 0 14 14.5l1.5-1.5a.5.5 0 0 0 .017-.689l-9.129-8.63c.747-.456 1.772-.839 3.112-.839a.5.5 0 0 0 .472-.334z"/>
                          </svg>
                        </button>
      
                        <button type="button" class="btn btn-success mx-1 px-1 py-1">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-all" viewBox="0 0 16 16" v-on:click="finalizarChamado(chamado)">
                            <path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992a.252.252 0 0 1 .02-.022zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486-.943 1.179z"/>
                          </svg>
                        </button>
                        <button type="button" class="btn btn-danger mx-1 px-1 py-1" data-bs-toggle="modal" data-bs-target="#modalDelete" v-on:click="selecionarChamado(chamado)">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3-fill" viewBox="0 0 16 16">
                            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z"/>
                          </svg>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END Table -->

      <!-- Modal Detalhes-->
      <div class="modal fade" id="modalDetalhesStaticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalDetalhesStaticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="modalDetalhesStaticBackdropLabel">Detalhes do Chamado</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="mb-12">
                <div class="mb-12">
                  <p class="itensModalDetalhes"> <span class="titlesModalDetalhes"> Descrição: </span> {{ descricao }} </p>

                  <p class="itensModalDetalhes"> <span class="titlesModalDetalhes"> Solicitante: </span> {{ solicitante }} </p>

                  <p class="itensModalDetalhes"> <span class="titlesModalDetalhes"> Localização: </span> {{ localizacao }} </p>

                  <p class="itensModalDetalhes"> <span class="titlesModalDetalhes"> Observação Técnica: </span> {{ observacoesTecnicas }} </p>

                  <p v-if="arrayMateriaisConsumidos.length !== 0"> 
                    <span class="titlesModalDetalhes"> Materiais usados: </span> <br>
                  
                    <span v-for="chamado in arrayMateriaisConsumidos" v-bind:key="chamado.idCha">
                      <small class="text-muted py-1"> <small class="fst-italic">Descricao:</small> {{ chamado.descricaoMat }} - </small>
                      <small class="text-muted py-1"> <small class="fst-italic">Qtde:</small> {{ chamado.quantidadeConsMat }} </small>
                      <br>
                    </span>
                  </p>

                  <p v-else-if="arrayMateriaisConsumidos.length === 0"> <span class="titlesModalDetalhes"> Materiais usados: </span> Não foi registrado materiais </p>

                  <p style="text-align: center; margin-bottom: 8px;"> <span class="titlesModalDetalhes"> Imagem do Chamado </span> </p>
                  
                  <p v-if="imagem == null && imagem2 == null" style="text-align: center;"> <span> Não foi registrado imagem </span> </p>
                  <div v-if="imagem !== null" class="imagemPreview" v-bind:style="imagem"></div>
                  <div v-if="imagem2 !== null" class="imagemPreview" v-bind:style="imagem2"></div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
            </div>
          </div>
        </div>
      </div>  
      <!-- END Modal Detalhes--> 

      <!-- Modal Delete -->
      <div class="modal fade" id="modalDelete" tabindex="-1" aria-labelledby="modalDeleteLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="modalDeleteLabel">Confirmar Exclusão</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" v-on:click="removeAlert('modalDelete'); removeDisableBtn()"></button>
            </div>
            <div class="modal-body">
              <p>
                Tem certeza que deseja excluir o chamado <br /> <span class="fw-bold"> {{ descricao }}</span> ?
              </p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" v-on:click="removeAlert('modalDelete'); removeDisableBtn()">Cancelar</button>
              <button type="button" class="btn btn-primary" id="btnDelete" v-on:click="excluirChamado(id)">Excluir</button>
            </div>
          </div>
        </div>
      </div>
      <!-- END Modal Delete -->

    </div>
  </div>
</template>

<script>
import LoaderComponent from './LoaderComponent.vue';
import { getChamados, updateAtenderChamado, updateFinalizarChamado, deleteChamado, pesquisaChamadosPorResidencialStatus } from '@/service/chamadosService';
import { getResidenciais } from '@/service/residencialService';
import { getConsumoMateriaisPorChamado } from '@/service/consumoMaterialService';
import { tratamentoError, formatarDataTela, formatarDataParaBD } from '@/helpers/utils';

export default {
  components: { LoaderComponent },

  data: function(){
    return {
      isLoading: false,
      token: sessionStorage.getItem('token'),

      id:"",
      idResidencial:"",
      descricao:"",
      solicitante: "",
      status: "",
      localizacao:"",
      observacoesTecnicas:"",
      imagem:"",
      imagem2:"",
      arrayMateriaisConsumidos: [],

      arrayResidencial: [],
      arrayChamados: [],
    }
  },

  methods: {  
    pesquisarChamado: async function(idResidencial, status){
      try {
        let $idResidencial = (idResidencial === "" ? "0" : idResidencial);
        let $status = (status === "" ? "Todos" : status);

        this.arrayChamados = await pesquisaChamadosPorResidencialStatus($idResidencial, $status, this.token);
        
      } catch (error) {
          const respError = tratamentoError(error);
          this.mostrarAlert("alertChamadosAbertos", respError, "alert-danger"); 
        
      } finally {
          setTimeout(() => {
            this.removeAlert("alertChamadosAbertos");
          }, 2000)
      }        
    },

    atenderChamado: function(chamadoSelecionado){
      if(chamadoSelecionado.statusCha !== 2){

        this.arrayChamados.forEach(async (chamado) => {
          if(chamado.idCha === chamadoSelecionado.idCha){
            chamado.statusCha = 2;
            chamado.dataFinalizacaoCha = "0000-00-00 00:00:00";
            chamado.concluinteCha = "";
            chamado.observacoesTecnicasCha = chamadoSelecionado.observacoesTecnicasCha;

            try {
              let resp = await updateAtenderChamado(chamado, this.token);

              if(resp !== 200){
                this.mostrarAlert("alertChamadosAbertos", "Houve um erro ao alterar o status do chamado!", "alert-danger");
                return
              }

              this.idResidencial = '';
              this.status = '';
              this.arrayChamados = await getChamados(this.token);  
              
            } catch (error) {
              const respError = tratamentoError(error);
              this.mostrarAlert("alertChamadosAbertos", respError, "alert-danger");  

            } finally {
              setTimeout(() => {
                this.removeAlert("alertChamadosAbertos");
              }, 2000)
            }  
          }
        })
        
        return;
      } 
    },

    finalizarChamado: function(chamadoSelecionado){
      if(chamadoSelecionado.statusCha !== 1){
        
        let dataFinalizacao = new Date(); 
        
        dataFinalizacao = formatarDataParaBD(dataFinalizacao);
     
        this.arrayChamados.forEach(async (chamado) => {
          
          if(chamado.idCha === chamadoSelecionado.idCha){
            chamado.statusCha = 1;
            chamado.dataFinalizacaoCha = dataFinalizacao;
            // chamado.idTecnico = 1;
            chamado.observacoesTecnicasCha = chamadoSelecionado.observacoesTecnicasCha;
            
            try {
              let resp = await updateFinalizarChamado(chamado, this.token);

              if(resp !== 200){
                this.mostrarAlert("alertChamadosAbertos", "Houve um erro ao alterar o status do chamado!", "alert-danger");
                return
              }

              this.idResidencial = '';
              this.status = '';
              this.arrayChamados = await getChamados(this.token);  
                            
            } catch (error) {
                const respError = tratamentoError(error);
                this.mostrarAlert("alertChamadosAbertos", respError, "alert-danger");  

            } finally {
                setTimeout(() => {
                  this.removeAlert("alertChamadosAbertos");
                }, 2000)
            } 
          }
        })

        return;
      } 
    },

    excluirChamado: async function(idChamado){
      try {
        let resp = await deleteChamado(idChamado, this.token);

        if(resp !== 200){
          this.mostrarAlert("modalDelete", "Houve um erro ao excluir o chamado!", "alert-danger");
          return
        }

        this.arrayChamados = await getChamados(this.token);  
        this.mostrarAlert("modalDelete", "Chamado excluído com sucesso!", "alert-success"); 
        this.disableBtn();
        
      } catch (error) {
        const respError = tratamentoError(error);
        this.mostrarAlert("modalDelete", respError, "alert-danger"); 
      }
    },

    selecionarMateriaisConsumidos: async function(idCha){
      try {
        this.arrayMateriaisConsumidos = await getConsumoMateriaisPorChamado(idCha, this.token);

      } catch (error) {
        console.log("Não foi possível carregar os materiais consumidos por este chamado");
      }
    },

    selecionarChamado: function(chamadoSelecionado){
      this.id = chamadoSelecionado.idCha;
      this.descricao = chamadoSelecionado.descricaoCha;
      this.solicitante = chamadoSelecionado.solicitanteCha;
      this.localizacao = chamadoSelecionado.area_externa ? "Área Externa" : `Bloco: ${chamadoSelecionado.bloco} - Andar: ${chamadoSelecionado.andar}º`;
      this.materialUsado = chamadoSelecionado.materialUsadoCha;
      this.observacoesTecnicas = chamadoSelecionado.observacoesTecnicasCha;
      this.imagem = chamadoSelecionado.imagemCha !== '-' ? {backgroundImage: `url(http://engecyber.com/app_new/img/${chamadoSelecionado.imagemCha}.jpg)`} : null;
      this.imagem2 = chamadoSelecionado.imagemCha2 == 1 ? {backgroundImage: `url(http://engecyber.com/app_new/imgfinalizada/${chamadoSelecionado.idCha}.jpg)`} : null;
    },

    mostrarAlert: function(idLocal, mensagem, tipoAlert){
      const local = document.getElementById(idLocal);      
      const isSectionAlert = local.getElementsByTagName('section')[0];

      if(isSectionAlert) local.removeChild(isSectionAlert);
      
      const sectionAlert = document.createElement('section');
      sectionAlert.setAttribute('id', 'alertId');
      sectionAlert.style.margin = "0px 20vw";
      sectionAlert.style.textAlign = "center";
      sectionAlert.style.fontWeight = "bold";

      sectionAlert.innerHTML = 
        `<div class="alert ${tipoAlert} alert-dismissible" role="alert">
          <p style="margin-bottom: 0">${mensagem}</p>
        </div>`;
          
      local.appendChild(sectionAlert);
    },
    
    removeAlert: function(idLocal){
      const local = document.getElementById(idLocal);

      if(local.getElementsByTagName('section').length === 0) return;

      const sectionAlert = local.getElementsByTagName('section')[0];

      if(sectionAlert) local.removeChild(sectionAlert);
    },

    formatarData: function (dt) {
      return formatarDataTela(dt)
    },

    disableBtn: function (){
      let btn = document.getElementById("btnDelete");
      btn.classList.add("disabled");
    },

    removeDisableBtn: function (){
      let btn = document.getElementById("btnDelete");

      if(btn.classList.contains("disabled")){
        btn.classList.remove("disabled");
      }
    }
  },

  async mounted(){
    try {
        this.isLoading = true;
        this.arrayChamados = await getChamados(this.token);  
        this.arrayResidencial = await getResidenciais(this.token);
    } catch (error) {
        this.mostrarAlert("cardBodyTable", "Houve um erro ao carregar as informações!", "alert-danger");      
        
        if(error.response.status === 401) {
          this.mostrarAlert("cardBodyTable", "Tempo de acesso finalizado.<br>Saia da aplicação e faça Login novamente!", "alert-danger");
          return
        } 
    } finally {
        this.isLoading = false;
    }
  },
 
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .container-fluid {
    padding-left: 65px;
  }

  .corEngecyber {
    background-color: #1271A9 !important;
  }

  .itensModalDetalhes {
    margin-bottom: 8px;
  }

  .titlesModalDetalhes{
    font-weight: bold;
  }

  .imagemPreview {
    width: 350px;
    height: 350px;
    display: block;
    cursor: pointer;
    margin: 0 auto 30px;
    background-size: cover;
    background-position: center center;
  }
  
</style>


