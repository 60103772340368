import axios from "axios";

const getMateriais = async (token) => {
  const resp = await axios.get(`${process.env.VUE_APP_URL_API}/material`, {headers:{'x-access-token': token}});
  return resp.data;
};

const postMaterial = async (objMaterial, token) => {
  const resp = await axios.post(`${process.env.VUE_APP_URL_API}/material`, {...objMaterial}, {headers:{'x-access-token': token}});
  return resp.status;
};

const updateMaterial = async (idMaterial, objMaterial, token) => {
    const resp = await axios.put(`${process.env.VUE_APP_URL_API}/material/${idMaterial}`, {...objMaterial}, {headers:{'x-access-token': token}});
    return resp.status;
  };

const deleteMaterial = async (idMaterial, token) => {
  const resp = await axios.delete(`${process.env.VUE_APP_URL_API}/material/${idMaterial}`, {headers:{'x-access-token': token}});
  return resp.status;
};

export { getMateriais, postMaterial, updateMaterial,  deleteMaterial};