<template>
  <loader-component v-if="isLoading"></loader-component>

  <side-navbar-component></side-navbar-component>

  <div class="container-fluid">
    <div>

      <!-- Card -->
      <div class="row mx-0">
        <div class="col-12" id="alertRelatorio">
          <div class="card mt-4">
            <div class="card-header py-1 bg-primary text-light fw-bold corEngecyber">
              Relatórios
            </div>
            <div class="card-body">
              <div class="mb-12">
                <label for="selectResidencial" class="form-label fw-bold">Tipo de Relatório</label>
                <div class="form-check">
                  <input class="form-check-input" type="radio" id="radioRelatorio1" v-model="radio" value="relatorioTotalChamados">
                  <label class="form-check-label" for="radioRelatorio1">
                    Relatório Total de Chamados
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" id="radioRelatorio2" v-model="radio" value="relatorioChamadosResidencial">
                  <label class="form-check-label" for="radioRelatorio2">
                    Relatório de Chamados por Residencial
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" id="radioRelatorio3" v-model="radio" value="relatorioConsumoMaterial">
                  <label class="form-check-label" for="radioRelatorio3">
                    Relatório de Consumo de Material por Residencial
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" id="radioRelatorio4" v-model="radio" value="relatorioProdutividade">
                  <label class="form-check-label" for="radioRelatorio4">
                    Relatório de Produtividade Técnica
                  </label>
                </div>
              </div>

              <div class="col-6">
                <div class="mb-12 mt-3">
                  <label for="selectResidencial" class="form-label fw-bold">Residencial</label>
                  <select v-if="radio === 'relatorioTotalChamados'" disabled class="form-select" aria-label="lista-residencial" id="selectResidencial" v-model="idResidencial">
                    <option v-for=" residencial in arrayResidencial" v-bind:key="residencial.idRes" v-bind:value="residencial.idRes"> {{ residencial.nomeRes }} </option>
                  </select>
                  <select v-else-if="radio === 'relatorioProdutividade'" disabled class="form-select" aria-label="lista-residencial" id="selectResidencial" v-model="idResidencial">
                    <option v-for=" residencial in arrayResidencial" v-bind:key="residencial.idRes" v-bind:value="residencial.idRes"> {{ residencial.nomeRes }} </option>
                  </select>
                  <select v-else class="form-select" aria-label="lista-residencial" id="selectResidencial" v-model="idResidencial">
                    <option v-for=" residencial in arrayResidencial" v-bind:key="residencial.idRes" v-bind:value="residencial.idRes"> {{ residencial.nomeRes }} </option>
                  </select>
                </div>
              </div>

              <div class="col-3">
                <div class="mb-12 mt-3 fw-bold">
                  <label for="inputDateIni" class="form-label">De:</label>
                  <input type="date" class="form-control" id="inputDateIni" v-model="dataIni">
                </div>
              </div>

              <div class="col-3">
                <div class="mb-12 mt-3 fw-bold">
                  <label for="inputDateFim" class="form-label">Até:</label>
                  <input type="date" class="form-control" id="inputDateFim" v-bind:min="dataIni" v-model="dataFim">
                </div>
              </div>

            </div>
            <div class="card-footer text-center" id="cardBodyTable">
              <button type="button" class="btn btn-primary mt-2 corEngecyber" v-on:click="gerarRelatorio(radio, idResidencial, dataIni, dataFim)">
                Gerar Relatório
            </button>
            </div>
          </div>

        </div>
      </div>
      <!-- END Card --> 

    </div>
  </div>
</template>

<script>
import LoaderComponent from './LoaderComponent.vue';
import { tratamentoError, formatarDataResidencial } from '@/helpers/utils';
import { getRelatorio } from "../service/relatorioService.js";
import { getResidenciais } from "@/service/residencialService.js";

export default {
  components: { LoaderComponent},

  data: function(){
    return {
      isLoading: false,
      token: sessionStorage.getItem('token'),

      radio:"",
      idResidencial:"",
      dataIni:"",
      dataFim:"",

      arrayResidencial: [],
      arrayResultados: [],
      
      totalChamadosAbertos:0,
      totalChamadosFechados:0,
      
      arrayChamadosAbertos: [],
      arrayChamadosFechados: [],
    }
  },

  methods: {

    gerarRelatorio: async function(radio, idResidencial, dataIni, dataFim){

      if(radio == "" || dataIni == "" || dataFim == ""){
        this.mostrarAlert("alertRelatorio", "Todos os campos devem ser preenchidos!", "alert-warning");

        setTimeout(() => {
          this.removeAlert("alertRelatorio");
        }, 2000);

        return
      }          
      
      let novaDataFim = `${dataFim} 23:59:59`

      let objRelatorio = {radio: radio, residencial: idResidencial, dataIni: dataIni, dataFim: novaDataFim}
          
      try {
        let resp = await getRelatorio(objRelatorio, this.token);
        
        this.arrayResultados = resp;
        
        if(this.arrayResultados.length === 0){
          this.mostrarAlert("alertRelatorio", "Não há registros no período informado!", "alert-warning");
          return
        }

        if(radio === "relatorioTotalChamados"){
          this.arrayChamadosAbertos = this.arrayResultados.map((item) => {
            return item.chamadosAbertos
          })
          
          this.arrayChamadosFechados = this.arrayResultados.map((item) => {
            return item.chamadosFechados
          })
          
          this.totalChamadosAbertos = this.arrayChamadosAbertos.reduce((acc, item) => {
            return acc + item
          })
          
          this.totalChamadosFechados = this.arrayChamadosFechados.reduce((acc, item) => {
            return acc + item
          })

          sessionStorage.setItem("dataIni", JSON.stringify(this.formatarDataSemHora(this.dataIni, 'BR')));
          sessionStorage.setItem("dataFim", JSON.stringify(this.formatarDataSemHora(this.dataFim, 'BR')));
          sessionStorage.setItem("resultadosRelatorio",JSON.stringify(this.arrayResultados));
          sessionStorage.setItem("totalChamadosAbertos",this.totalChamadosAbertos);
          sessionStorage.setItem("totalChamadosFechados",this.totalChamadosFechados);
        }

        if(radio === "relatorioChamadosResidencial"){

          this.arrayChamadosAbertos = this.arrayResultados.map((item) => {
            return item.dataAberturaCha
          })
          
          this.arrayChamadosFechados = this.arrayResultados.filter((item) => {
            return item.dataFinalizacaoCha !== null
          })
          
          this.totalChamadosAbertos = this.arrayChamadosAbertos.length;        
          this.totalChamadosFechados = this.arrayChamadosFechados.length;

          sessionStorage.setItem(", 'BR'", JSON.stringify(this.formatarDataSemHora(this.dataIni, 'BR')));
          sessionStorage.setItem("dataFim", JSON.stringify(this.formatarDataSemHora(this.dataFim, 'BR')));
          sessionStorage.setItem("resultadosRelatorio",JSON.stringify(this.arrayResultados));
          sessionStorage.setItem("totalChamadosAbertos",this.totalChamadosAbertos);
          sessionStorage.setItem("totalChamadosFechados",this.totalChamadosFechados);
        }

        if(radio === "relatorioConsumoMaterial"){

          sessionStorage.setItem("dataIni", JSON.stringify(this.formatarDataSemHora(this.dataIni, 'BR')));
          sessionStorage.setItem("dataFim", JSON.stringify(this.formatarDataSemHora(this.dataFim, 'BR')));
          sessionStorage.setItem("resultadosRelatorio",JSON.stringify(this.arrayResultados));
        }

        if(radio === "relatorioProdutividade"){
          this.arrayChamadosFechados = this.arrayResultados.map((item) => {
            return item.quantidade
          })

          this.totalChamadosFechados = this.arrayChamadosFechados.reduce((acc, item) => {
            return acc + item
          })

          sessionStorage.setItem("dataIni", JSON.stringify(this.formatarDataSemHora(this.dataIni, 'BR')));
          sessionStorage.setItem("dataFim", JSON.stringify(this.formatarDataSemHora(this.dataFim, 'BR')));
          sessionStorage.setItem("resultadosRelatorio",JSON.stringify(this.arrayResultados));
          sessionStorage.setItem("totalChamadosFechados",this.totalChamadosFechados);
        } 

        this.mostrarAlert("alertRelatorio", "Relatório gerado com sucesso!", "alert-success");         
        
        switch (radio) {
            case 'relatorioTotalChamados':
              this.goRelatorio('/relatorioTotalChamados');
            break

            case 'relatorioChamadosResidencial':
              this.goRelatorio('/relatorioChamadosResidencial');
            break

            case 'relatorioConsumoMaterial':
              this.goRelatorio('/relatorioConsumoMaterial');
            break
                
            case 'relatorioProdutividade':
              this.goRelatorio('/relatorioProdutividade');
            break
        }
        
      } catch (error) {
        const respError = tratamentoError(error);
        this.mostrarAlert("modalNewStaticBackdrop", respError, "alert-danger"); 

      } finally {
          setTimeout(() => {
            this.removeAlert("alertRelatorio");
            this.limparDados();
          }, 3000)
      }

    },

    limparDados:function(){
      this.radio = "";
      this.idResidencial = "";
      this.dataIni = "";
      this.dataFim = "";
    },

    mostrarAlert: function(idLocal, mensagem, tipoAlert){
      const local = document.getElementById(idLocal);      
      const isSectionAlert = local.getElementsByTagName('section')[0];

      if(isSectionAlert) local.removeChild(isSectionAlert);
      
      const sectionAlert = document.createElement('section');
      sectionAlert.setAttribute('id', 'alertId');
      sectionAlert.style.margin = "2vh 20vw 0vh";
      sectionAlert.style.textAlign = "center";
      sectionAlert.style.fontWeight = "bold";

      sectionAlert.innerHTML = 
        `<div class="alert ${tipoAlert} alert-dismissible" role="alert">
          <p style="margin-bottom: 0">${mensagem}</p>
        </div>`;
          
      local.appendChild(sectionAlert);
    },
    
    removeAlert: function(idLocal){
      const local = document.getElementById(idLocal);

      if(local.getElementsByTagName('section').length === 0) return;

      const sectionAlert = local.getElementsByTagName('section')[0];

      if(sectionAlert) local.removeChild(sectionAlert);
    },

    formatarDataSemHora: function (dt, local) {
      return formatarDataResidencial(dt, local);
    },

    goRelatorio: function(caminho){
      this.$router.push({path: caminho})
    },
  },

 async mounted(){
  try {
      this.isLoading = true;
      this.arrayResidencial = await getResidenciais(this.token);

    } catch (error) {
        this.mostrarAlert("cardBodyTable", "Houve um erro ao carregar as informações!", "alert-danger");      
        
        if(error.response.status === 401) {
          this.mostrarAlert("cardBodyTable", "Tempo de acesso finalizado.<br>Saia da aplicação e faça Login novamente!", "alert-danger");
          return
        } 
    } finally {
        this.isLoading = false;
    }
  },
 
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .container-fluid {
    padding-left: 65px;
  }

  .corEngecyber {
    background-color: #1271A9 !important;
  }

  .bgGrey {
    background-color: lightgrey;
  }

</style>
