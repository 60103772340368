<template>
    <div class="d-flex flex-row-reverse">
        <button type="button" class="btn btn-primary mt-1 mx-5 corEngecyber" v-on:click="voltar">
            Voltar
        </button>
        <button type="button" class="btn btn-primary mt-1 mx-5 corEngecyber" v-on:click="imprimir">
            Imprimir
        </button>
    </div>
    <div class="row mx-0" >
        <div class="col-12">
            <div class="card mt-1">
            <div class="card-header py-1 bg-primary text-light fw-bold text-center corEngecyber">
                ENGECYBER
            </div>
            <div class="card-body" id="cardBodyTable">
                <h3 class="py-1 fw-bold text-center bgGrey">Relatório de Chamados por Residencial</h3>
                <h5 class="pt-1 pb-1 fw-bold text-center">Referente ao Período de {{ dataIni }} até {{ dataFim }}</h5>
                <h4 class="pb-1 fw-bold text-center"> {{ residencial.nomeRes }}</h4>
                <div class="table-responsive">
                <table class="table table-bordered table-striped table-hover">
                    <thead>
                    <tr>
                        <th class="col text-center py-1 bgGrey">Descrição</th>
                        <th class="col text-center py-1 bgGrey">Data de Abertura</th>
                        <th class="col text-center py-1 bgGrey">Data de Finalização</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for=" resultado in arrayResultados " v-bind:key="resultado.idCha">
                        <td class="text-left align-middle py-1"> {{ resultado.descricaoCha}} </td>
                        <td class="text-center align-middle py-1"> {{ this.formatarData(resultado.dataAberturaCha, 'BR') }} </td>
                        <td class="text-center align-middle py-1"> {{ this.formatarData(resultado.dataFinalizacaoCha, 'BR') }} </td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                        <th class="col text-center py-1 bgGrey" colspan="3">Total Geral</th>
                    </tr>
                    <tr>
                        <th class="col text-center py-1 bgGrey"> Chamados Abertos: {{totalChamadosAbertos}} </th>
                        <th class="col text-center py-1 bgGrey"> Chamados Finalizados: {{totalChamadosFechados}} </th>
                        <th class="col text-center py-1 bgGrey"> {{ (totalChamadosFechados * 100 / totalChamadosAbertos).toFixed(2) }}% de Chamados Finalizados </th>
                    </tr>
                    </tfoot>
                </table>
                </div>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
import { formatarDataTela } from '@/helpers/utils';

export default {
    data: function(){
        return{
            dataIni:'',
            dataFim:'',
            arrayResultados: [],
            totalChamadosAbertos:'',
            totalChamadosFechados:'',
            residencial:{}
        }
    },

    mounted(){
        this.dataIni = JSON.parse(sessionStorage.getItem('dataIni'));
        this.dataFim = JSON.parse(sessionStorage.getItem('dataFim'));
        this.arrayResultados = JSON.parse(sessionStorage.getItem('resultadosRelatorio'));
        this.totalChamadosAbertos = sessionStorage.getItem('totalChamadosAbertos');
        this.totalChamadosFechados = sessionStorage.getItem('totalChamadosFechados');
        this.residencial = this.arrayResultados[0];
    },

    methods: {
        formatarData: function (dt) {
            return formatarDataTela(dt);
        },

        imprimir: function (){
            window.print();
        },

        voltar: function(){
            this.$router.back()
        }
    }
}
</script>

<style>
  .corEngecyber {
    background-color: #1271A9 !important;
  }

  .bgGrey {
    background-color: lightgrey !important;
  }
</style>