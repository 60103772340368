import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'

import { createApp } from 'vue';
import { createRouter, createWebHistory } from "vue-router";
import App from './App.vue';

import LoginComponent from "./components/LoginComponent";
import RecuperarSenhaComponent from "./components/RecuperarSenhaComponent";
import SideNavbarComponent from "./components/SideNavbarComponent";

import AtividadeComponent from "./components/AtividadeComponent";
import ChamadosComponent from "./components/ChamadosComponent";
import ConsumoMaterialComponent from "./components/ConsumoMaterialComponent";
import CronogramaComponent from "./components/CronogramaComponent";
import EnvioRelatorioComponent from "./components/EnvioRelatorioComponent";
import MaterialComponent from "./components/MaterialComponent";
import RelatorioComponent from "./components/RelatorioComponent";
import ResidencialComponent from "./components/ResidencialComponent";
import SindicoComponent from "./components/SindicoComponent";
import TecnicoComponent from "./components/TecnicoComponent";
import UsuarioComponent from "./components/UsuarioComponent";

import AberturaChamado from "./components/AberturaChamado";
import RelatorioTotalChamados from "./components/RelatorioTotalChamados";
import RelatorioChamadosResidencial from "./components/RelatorioChamadosResidencial";
import RelatorioConsumoMaterial from "./components/RelatorioConsumoMaterial";
import RelatorioProdutividade from "./components/RelatorioProdutividade";

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: "/", component: LoginComponent},
        { path: "/recuperarSenha", component: RecuperarSenhaComponent},
        { path: "/atividade", component: AtividadeComponent},
        { path: "/chamados", component: ChamadosComponent},
        { path: "/consumoMaterial", component: ConsumoMaterialComponent},
        { path: "/cronograma", component: CronogramaComponent},
        { path: "/envioRelatorio", component: EnvioRelatorioComponent},
        { path: "/material", component: MaterialComponent},
        { path: "/relatorio", component: RelatorioComponent},
        { path: "/residencial", component: ResidencialComponent},
        { path: "/sindico", component: SindicoComponent},
        { path: "/tecnico", component: TecnicoComponent},
        { path: "/usuario", component: UsuarioComponent},

        { path: "/abertura", component: AberturaChamado},
        { path: "/relatorioTotalChamados", component: RelatorioTotalChamados},
        { path: "/relatorioChamadosResidencial", component: RelatorioChamadosResidencial},
        { path: "/relatorioConsumoMaterial", component: RelatorioConsumoMaterial},
        { path: "/relatorioProdutividade", component: RelatorioProdutividade},
    ]
})

const app = createApp(App);
app.component("SideNavbarComponent", SideNavbarComponent);
app.use(router);
app.mount('#app');

