<template>
  <div class="container-fluid corEngecyber" id="modalAlert">
      <div class="row justify-content-center">
          <div class="col-md-4 mt-5">
              <div class="card shadow-lg border-0 rounded-lg mt-5 mx-5">
                  <div class="card-header text-center">
                    <img src="../assets/logo.png" class="logo">
                  </div>
                  <div class="card-body">
                      <form>
                          <div class="form-floating mb-3">
                              <input class="form-control" id="inputUser" type="text"  v-model="usuario"/>
                              <label for="inputUser">Usuário</label>
                          </div>

                          <div class="form-floating mb-3">
                              <input class="form-control" id="inputPassword" type="password" v-model="senha"/>
                              <label for="inputPassword">Senha</label>
                          </div>

                          <div class="d-flex align-items-center justify-content-center mt-4 mb-0">
                              <button type="submit" class="btn btn-primary" v-on:click="loginSistema">Acessar</button>
                          </div>
                      </form>
                  </div>
                  <!-- <div class="card-footer text-center py-3">
                    <button class="btn-sm btn btn-outline-secondary" v-on:click="recuperarSenha">Recuperar a Senha</button>
                  </div> -->
              </div>
          </div>
      </div>
  </div> 
</template>

<script>
import { loginUsuario } from '@/service/loginService';

export default {
  data: function(){
    return {
      usuario: "",
      senha: "",
    }
  },

  methods: {
    loginSistema: async function(event){
      event.preventDefault();
      this.usuario = this.usuario.trim();
      this.senha = this.senha.trim();

      if(this.usuario === "" || this.senha === "" ){
        this.mostrarAlert("modalAlert", "Existem campos vazios!", "alert-warning");

      } else {
        let objUsuario = { usuario: this.usuario, senha: this.senha };

        try {
          let resp = await loginUsuario(objUsuario);
          sessionStorage.setItem('token', resp.token);
          
          this.$router.replace('/chamados');
          
        } catch (error) {
          if(error.message == 'Network Error'){
            this.mostrarAlert("modalAlert", "Falha ocorrida na conexão com a API!", "alert-danger");            
          }

          if(error.response.status == 400){
            this.mostrarAlert("modalAlert", "Usuário ou senha inválidos!", "alert-danger");
            return
          }          
        }
      }
    },

    mostrarAlert: function(idLocal, mensagem, tipoAlert){
      const local = document.getElementById(idLocal);      
      
      const sectionAlert = document.createElement('section');
      sectionAlert.setAttribute('id', 'alertId');
      sectionAlert.style.margin = "20px 20vw";
      sectionAlert.style.textAlign = "center";
      sectionAlert.style.fontWeight = "bold";

      sectionAlert.innerHTML = 
        `<div class="alert ${tipoAlert} alert-dismissible" role="alert">
          <p style="margin-bottom: 0">${mensagem}</p>
        </div>`;
          
      local.appendChild(sectionAlert);

      setTimeout(() => {
        local.removeChild(sectionAlert);
      }, 1500);
    },

    recuperarSenha: function(){
      this.$router.replace('/recuperarSenha');
    }
  }
}

</script>

<style scoped>
.corEngecyber {
    background-color: #1271A9 !important;
    height: 100vh
  }

.logo {
  width: 80px
}

</style>
