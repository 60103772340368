<template>
  <loader-component v-if="isLoading"></loader-component>

  <side-navbar-component></side-navbar-component>
  
  <div class="container-fluid">

    <div>

      <!-- Card -->
      <div class="row mx-0">
        <div class="col-12" id="alertCronograma">
          <div class="card mt-4">
            <div class="card-header py-1 bg-primary text-light fw-bold corEngecyber">
              Cronograma de Atividades por Mês:
            </div>

            <div class="card-body py-2">
              <div class="row align-items-end">
                <div class="col-5">
                  <div class="mb-12">
                    <label for="selectResidencial" class="form-label fw-bold">Residencial</label>
                    <select class="form-select" aria-label="lista-residencial" id="selectResidencial" v-model="idResidencial">
                      <option v-for=" residencial in arrayResidencial" v-bind:key="residencial.idRes" v-bind:value="residencial.idRes"> {{ residencial.nomeRes }} </option>
                    </select>
                  </div>
                </div>
                <div class="col-3">
                  <div class="mb-12">
                    <label for="selectMes" class="form-label fw-bold">Mês</label>
                    <select class="form-select" aria-label="cargo-tecnico" id="selectMes" v-model="mes">
                      <option value="1">Janeiro</option>
                      <option value="2">Fevereiro</option>
                      <option value="3">Março</option>
                      <option value="4">Abril</option>
                      <option value="5">Maio</option>
                      <option value="6">Junho</option>
                      <option value="7">Julho</option>
                      <option value="8">Agosto</option>
                      <option value="9">Setembro</option>
                      <option value="10">Outubro</option>
                      <option value="11">Novembro</option>
                      <option value="12">Dezembro</option>
                    </select>
                  </div>
                </div>

                <div class="col-3 ms-5">
                  <button type="button" class="btn btn-primary px-4 corEngecyber" v-on:click="gerarCronograma(idResidencial, mes)">
                    Gerar Cronograma
                  </button>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
      <!-- END Card --> 

      <!-- Table -->
      <div class="row mx-0">
        <div class="col-12">
          <div class="card mt-4">
            <div class="card-header py-1 bg-primary text-light fw-bold corEngecyber">
              Residencial:
            </div>
            <div class="card-body" id="cardBodyTable">
              <div class="table-responsive">
                <table class="table table-bordered table-striped table-hover">
                  <thead>
                    <tr>
                      <th class="col text-center py-1">Atividade</th>
                      <th class="col text-center py-1">Área</th>
                      <th class="col text-center py-1">Periodicidade</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="cronogramaAti in arrayCronogramaAti" v-bind:key="cronogramaAti.idAti">
                      <td class="text-left align-middle py-1"> {{ cronogramaAti.nomeAti }} </td>
                      <td class="text-left align-middle py-1"> {{ cronogramaAti.nomeAre }} </td>
                      <td class="text-left align-middle py-1"> {{ cronogramaAti.nomePer }} </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END Table -->

    </div>

  </div>
</template>

<script>
import LoaderComponent from './LoaderComponent.vue';
import { tratamentoError } from '@/helpers/utils';
import { getCronogramaAtividades } from '@/service/cronogramaService';
import { getResidenciais } from '@/service/residencialService';

export default {
  components: { LoaderComponent },

  data: function(){
    return {
      isLoading: false,
      token: sessionStorage.getItem('token'),
      
      idResidencial:"",
      mes:"",

      arrayResidencial: [],
      arrayCronogramaAti: [],
    }
  },

  methods: {
    gerarCronograma: async function(idResidencial, mes){
      if(idResidencial == "" || mes == ""){
        this.mostrarAlert("alertCronograma", "Todos os campos devem ser preenchidos!", "alert-warning");

        setTimeout(() => {
          this.removeAlert("alertCronograma");
        }, 2000);

        return
      } 

      try {
        this.isLoading = true;
        let resp = await getCronogramaAtividades(idResidencial, mes, this.token);

        this.arrayCronogramaAti = resp;
        
      } catch (error) {
        const respError = tratamentoError(error);
        this.mostrarAlert("alertCronograma", respError, "alert-danger"); 

      } finally {
        this.isLoading = false;

        setTimeout(() => {
          this.removeAlert("alertCronograma");
        }, 2000);
      }
    },

    mostrarAlert: function(idLocal, mensagem, tipoAlert){
      const local = document.getElementById(idLocal);      
      const isSectionAlert = local.getElementsByTagName('section')[0];

      if(isSectionAlert) local.removeChild(isSectionAlert);
      
      const sectionAlert = document.createElement('section');
      sectionAlert.setAttribute('id', 'alertId');
      sectionAlert.style.margin = "2vh 20vw 0vh";
      sectionAlert.style.textAlign = "center";
      sectionAlert.style.fontWeight = "bold";

      sectionAlert.innerHTML = 
        `<div class="alert ${tipoAlert} alert-dismissible" role="alert">
          <p style="margin-bottom: 0">${mensagem}</p>
        </div>`;
          
      local.appendChild(sectionAlert);
    },
    
    removeAlert: function(idLocal){
      const local = document.getElementById(idLocal);

      if(local.getElementsByTagName('section').length === 0) return;

      const sectionAlert = local.getElementsByTagName('section')[0];

      if(sectionAlert) local.removeChild(sectionAlert);
    },
  },

  async mounted(){
    try {
        this.isLoading = true;
        this.arrayResidencial = await getResidenciais(this.token);  
    } catch (error) {
        this.mostrarAlert("cardBodyTable", "Houve um erro ao carregar as informações!", "alert-danger");      
        
        if(error.response.status === 401) {
          this.mostrarAlert("cardBodyTable", "Tempo de acesso finalizado.<br>Saia da aplicação e faça Login novamente!", "alert-danger");
          return
        }
    } finally {
        this.isLoading = false;
    }
  },
 
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .container-fluid {
    padding-left: 65px;
  }

  .corEngecyber {
    background-color: #1271A9 !important;
  }

</style>
