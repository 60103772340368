import axios from "axios";

const getResidenciais = async (token) => {
  const resp = await axios.get(`${process.env.VUE_APP_URL_API}/residencial`, {headers:{'x-access-token': token}});
  return resp.data;
};

const postResidencial = async (objResidencial, token) => {
  const resp = await axios.post(`${process.env.VUE_APP_URL_API}/residencial`, {...objResidencial}, {headers:{'x-access-token': token}});
  return resp.status;
};

const updateResidencial = async (idResidencial, objResidencial, token) => {
  const resp = await axios.put(`${process.env.VUE_APP_URL_API}/residencial/${idResidencial}`, {...objResidencial}, {headers:{'x-access-token': token}});
  return resp.status;
  };

const deleteResidencial = async (idResidencial, token) => {
  const resp = await axios.delete(`${process.env.VUE_APP_URL_API}/residencial/${idResidencial}`, {headers:{'x-access-token': token}});
  return resp.status;
};

export { getResidenciais, postResidencial, updateResidencial,  deleteResidencial};

