import axios from "axios";

const getRelatorios = async (token) => {
  const resp = await axios.get(`${process.env.VUE_APP_URL_API}/envioRelatorio`, {headers:{'x-access-token': token}});
  return resp.data;
};

const postRelatorio = async (objEnvRelatorio, token) => {
  const resp = await axios.post(`${process.env.VUE_APP_URL_API}/envioRelatorio`, {...objEnvRelatorio}, {headers:{'x-access-token': token}});
  return resp.data;
};

const deleteRelatorio = async (idRelatorio, token) => {
  const resp = await axios.delete(`${process.env.VUE_APP_URL_API}/envioRelatorio/${idRelatorio}`, {headers:{'x-access-token': token}});
  return resp.data;
};

export { getRelatorios, postRelatorio, deleteRelatorio};
