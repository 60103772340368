<template>
  
  <side-navbar-component></side-navbar-component>  

  <div class="container-fluid">
      <div class="row justify-content-center">
          <div class="col-md-6 mt-5" id="cardAberturaChamado">
              <div class="card shadow-lg border-0 rounded-lg mt-5 mx-5">
                  
                  <div class="card-header py-1 bg-primary text-light fw-bold corEngecyber">
                        Abertura de Chamados
                  </div>
                  <div class="card-body">
                        <div class="mb-12">
                            <label for="selectResidencialSin" class="form-label">Residencial</label>
                            <select class="form-select" aria-label="lista-residenciais" id="selectResidencialSin" v-model="residencial" v-on:change="filtrarSolicitante()">
                                <option v-for=" residencial in arrayResidencial" v-bind:key="residencial.idRes" v-bind:value="residencial.idRes"> {{ residencial.nomeRes }} </option>
                            </select>
                        </div>

                        <div class="mb-12">
                            <label for="inputSolicitante" class="form-label">Solicitante:</label>
                            <select class="form-select" aria-label="lista-residenciais" id="selectResidencialSin" v-model="solicitante">
                                <option v-for=" solicitante in arraySindicosAndTecnicos " v-bind:key="solicitante.id" v-bind:value="solicitante.id"> {{ solicitante.nome }} </option>
                            </select>
                        </div>

                        <div class="mb-12">
                            <label for="inputDescricao" class="form-label">Descrição:</label>
                            <textarea type="text" class="form-control" id="inputDescricao" v-model="descricao"></textarea>
                        </div>
                        
                        <div class="mb-12">
                            <label class="form-label">Localização:</label>
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" role="switch" id="SwitchCheck" v-model="areaExterna">
                                <label class="form-check-label" for="SwitchCheck">Área Externa</label>
                            

                                <span class="" v-if="areaExterna == 0">
                                    <label class="form-label ms-4 me-2">Bloco:</label>
                                    <select class="border border-secondary-subtle rounded me-3 px-1" v-model="blocoSelecionado">
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                        <option value="13">13</option>
                                        <option value="14">14</option>
                                        <option value="15">15</option>
                                        <option value="16">16</option>
                                        <option value="17">17</option>
                                        <option value="18">18</option>
                                        <option value="19">19</option>
                                        <option value="20">20</option>
                                    </select>

                                    <label class="form-label me-2">Andar:</label>
                                    <select class="border border-secondary-subtle rounded me-5 px-1" v-model="andarSelecionado">
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                    </select>
                                </span>
                            </div>
                        </div>

                  </div>

                  <div class="card-footer text-center py-3">
                    <button type="button" class="btn btn-secondary mx-4 px-4" v-on:click="limparDados(), removeAlert(cardAberturaChamado)">Cancelar</button>
                    <button type="button" class="btn btn-primary mx-4 px-2" v-on:click="abrirChamado(residencial, solicitante, descricao, areaExterna, blocoSelecionado, andarSelecionado)">Abrir Chamado</button>
                  </div>
                 
              </div>
          </div>
      </div>
  </div> 

</template>

<script>
import { getResidenciais } from '@/service/residencialService';
import { getSindicos } from '@/service/sindicoService';
import { getTecnicos } from '@/service/tecnicoService';
import { postChamado } from '@/service/chamadosService';
import { tratamentoError } from '@/helpers/utils';

export default {
    data: function(){
        return {
            token: sessionStorage.getItem('token'),
           
            residencial: 0,
            solicitante: "",
            descricao:"",
            areaExterna: 0,
            blocoSelecionado: 0,
            andarSelecionado: 0,
            
            arrayResidencial: [],
            arraySindicos: [],
            arrayTecnicos: [],
            arrayTecnicosFiltrados: [],
            arraySindicosFiltrados: [],
            arraySindicosAndTecnicos: [],
        }
    },

    methods: {
        abrirChamado: async function($residencial, $solicitante, $descricao, $areaExterna, $blocoSelecionado, $andarSelecionado){

            try {
                let data = new Date();
                let dia = data.getDate();
                let mes = data.getMonth();
                mes++;
                let ano = data.getFullYear();
                let hora = data.getHours();
                let minutos = data.getMinutes();
                let novaData = `${ano}-${mes}-${dia} ${hora}:${minutos}:00`

                // let formData = new FormData();
                // formData.append('descricaoCha', $descricao);
                // formData.append('solicitanteCha', $solicitante);
                // formData.append('idResidencial', $residencial);
                // formData.append('dataAberturaCha', novaData);
                // formData.append('areaExterna', $areaExterna);
                // formData.append('blocoSelecionado', $blocoSelecionado);
                // formData.append('andarSelecionado', $andarSelecionado);
                // formData.append('file', this.imagem);

                let objChamado = {descricaoCha: $descricao, solicitanteCha: $solicitante, idResidencialCha: $residencial, dataAberturaCha: novaData, areaExternaCha: $areaExterna === true ? 1 : 0, blocoSelecionadoCha: $blocoSelecionado, andarSelecionadoCha: $andarSelecionado}

                await postChamado(objChamado, this.token);

                this.mostrarAlert("cardAberturaChamado", "Chamado aberto com sucesso!", "alert-success");
                
            } catch (error) {
                const respError = tratamentoError(error);
                this.mostrarAlert("cardAberturaChamado", respError, "alert-danger");            
            } finally {
                setTimeout(() => {
                    this.removeAlert("cardAberturaChamado");
                    this.limparDados();
                }, 2000)
            }
        },

        filtrarSolicitante: async function(){
            const residencial = this.arrayResidencial.find((item) => {
                return this.residencial === item.idRes;
            })
            
            this.arraySindicosFiltrados = this.arraySindicos.filter((item) => {
                return item.nomeRes === residencial.nomeRes
            })

            this.arraySindicosFiltrados = this.arraySindicosFiltrados.map((item) => {
                return {id: item.idSin, nome: item.nomeSin}
            })

            this.arrayTecnicosFiltrados = this.arrayTecnicos.map((item) => {
                return {id: item.idTec, nome: item.nomeTec}
            })

            this.arraySindicosAndTecnicos = this.arraySindicosFiltrados.concat(this.arrayTecnicosFiltrados);
        },

        // uploadImagem: function(){
        //     this.imagem = this.$refs.fileUpload.files[0]
        // },

        mostrarAlert: function(idLocal, mensagem, tipoAlert){
            const local = document.getElementById(idLocal);      
            const isSectionAlert = local.getElementsByTagName('section')[0];

            if(isSectionAlert){
                local.removeChild(isSectionAlert);
            }
            
            const sectionAlert = document.createElement('section');
            sectionAlert.setAttribute('id', 'alertId');
            sectionAlert.style.margin = "30px 0";
            sectionAlert.style.textAlign = "center";
            sectionAlert.style.fontWeight = "bold";

            sectionAlert.innerHTML = 
                `<div class="alert ${tipoAlert} alert-dismissible" role="alert">
                <p style="margin-bottom: 0">${mensagem}</p>
                </div>`;
                
            local.appendChild(sectionAlert);
        },

        removeAlert: function(idLocal){
            const local = document.getElementById(idLocal);
            const sectionAlert = local.getElementsByTagName('section')[0];

            if(sectionAlert){
                local.removeChild(sectionAlert);
            }
        },

        limparDados:function(){
            this.descricao = "";
            this.solicitante = "";
            this.residencial = "";
            this.areaExterna = "";
            this.blocoSelecionado = "";
            this.andarSelecionado = "";
        },
    },

    async mounted(){
        try {
            this.arrayResidencial = await getResidenciais(this.token);
            this.arraySindicos = await getSindicos(this.token);
            this.arrayTecnicos = await getTecnicos(this.token);
            
        } catch (error) {
            this.mostrarAlert("cardBody", "Houve um erro ao carregar as informações!", "alert-danger");      
        
            if(error.response.status === 401) {
                this.mostrarAlert("cardBody", "Tempo de acesso finalizado.<br>Saia da aplicação e faça Login novamente!", "alert-danger");
                return
            }
        } 
    },
}
</script>

<style scoped>

</style>