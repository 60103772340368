import axios from "axios";

const getChamados = async (token) => {
  const resp = await axios.get(`${process.env.VUE_APP_URL_API}/chamados`, {headers:{'x-access-token': token}});
  return resp.data;
};

const updateAtenderChamado = async (chamado, token) => {
  const resp = await axios.put(`${process.env.VUE_APP_URL_API}/chamados/atender`, {chamado}, {headers:{'x-access-token': token}});
  return resp.status;
};

const updateFinalizarChamado = async (chamado, token) => {
  const resp = await axios.put(`${process.env.VUE_APP_URL_API}/chamados/finalizar`, {chamado}, {headers:{'x-access-token': token}});
  return resp.status;
};

const deleteChamado = async (idChamado, token) => {
  const resp = await axios.delete(`${process.env.VUE_APP_URL_API}/chamados/${idChamado}`, {headers:{'x-access-token': token}});
  return resp.status;
};

const pesquisaChamadosPorResidencialStatus = async (idResidencial, status, token) => {
  const resp = await axios.get(`${process.env.VUE_APP_URL_API}/chamados/pesquisa/${idResidencial}/${status}`, {headers:{'x-access-token': token}});
  return resp.data;
};

const postChamado = async (formData, token) => {
  const resp = await axios.post(`${process.env.VUE_APP_URL_API}/chamados`, formData, {headers:{'x-access-token': token}});
  return resp.data;
};


export { getChamados, updateAtenderChamado, updateFinalizarChamado, deleteChamado, pesquisaChamadosPorResidencialStatus, postChamado };