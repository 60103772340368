<template>
  <loader-component v-if="isLoading"></loader-component>

  <side-navbar-component></side-navbar-component>

  <div class="container-fluid">

    <div>
      <!-- Buttons -->
      <div class="row mx-0 mt-2">
        <div class="col-12">
          <div class="d-flex flex-row-reverse">
            <button type="button" class="btn btn-primary mt-2 corEngecyber" data-bs-toggle="modal" data-bs-target="#modalNewStaticBackdrop">
              Novo
            </button>
          </div>
        </div>
      </div>
      <!-- END Buttons -->

      <!-- Modal New-->
      <div class="modal fade" id="modalNewStaticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalNewStaticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="modalNewStaticBackdropLabel">Cadastro de Residencial</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" v-on:click="limparDados(); removeAlert('modalNewStaticBackdrop')"></button>
            </div>
            <div class="modal-body">
              <div class="mb-12">
                <label for="inputResidencial" class="form-label">Residencial</label>
                <input type="text" class="form-control" id="inputResidencial" v-model="nome">
              </div>
              <div class="mb-12">
                <label for="inputEndereco" class="form-label">Endereço</label>
                <input type="text" class="form-control" id="inputEndereco" v-model="endereco">
              </div>
              <div class="mb-12">
                <label for="inputNumeroBlocos" class="form-label">Nº de Blocos</label>
                <input type="number" class="form-control" id="inputNumeroBlocos" v-model="numeroBlocos">
              </div>
              <div class="mb-12">
                <label for="inputDataCadastro" class="form-label">Data de Cadastro</label>
                <input type="date" class="form-control" id="inputDataCadastro" v-model="data">
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" v-on:click="limparDados(); removeAlert('modalNewStaticBackdrop')">Cancelar</button>
              <button type="button" class="btn btn-primary" v-on:click="cadastrarResidencial(nome, endereco, numeroBlocos, data)">Salvar</button>
            </div>
          </div>
        </div>
      </div>  
      <!-- END Modal New-->  

      <!-- Table -->
      <div class="row mx-0">
        <div class="col-12">
          <div class="card mt-4">
            <div class="card-header py-1 bg-primary text-light fw-bold corEngecyber">
              Residenciais Cadastrados
            </div>
            <div class="card-body" id="cardBodyTable">
              <div class="table-responsive">
                <table class="table table-bordered table-striped table-hover">
                  <thead>
                    <tr>
                      <th class="col text-center py-1">Residencial</th>
                      <th class="col text-center py-1">Data Cadastro</th>
                      <th class="col text-center py-1">Endereço</th>
                      <th class="col text-center py-1">Alterar</th>
                      <th class="col text-center py-1">Excluir</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="residencial in arrayResidencial" v-bind:key="residencial.idRes">
                      <td class="text-left align-middle py-1"> <small> {{ residencial.nomeRes }} </small> </td>
                      <td class="text-center align-middle py-1"> <small> {{ this.formatarData(residencial.dataRes, 'BR') }} </small> </td>
                      <td class="text-center align-middle py-1"> <small> {{ residencial.logradouro }} </small> </td>
                      <td class="text-center align-middle py-1">
                        <button type="button" class="btn btn-warning px-2 py-1" data-bs-toggle="modal" data-bs-target="#modalEditStaticBackdrop" v-on:click="selecionarResidencial(residencial)">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-fill" viewBox="0 0 16 16">
                            <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                          </svg>
                        </button>
                      </td>
                      <td class="text-center align-middle py-1">
                        <button type="button" class="btn btn-danger px-2 py-1" data-bs-toggle="modal" data-bs-target="#modalDelete" v-on:click="selecionarResidencial(residencial)">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3-fill" viewBox="0 0 16 16">
                            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z"/>
                          </svg>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END Table -->

      <!-- Modal Edit-->
      <div class="modal fade" id="modalEditStaticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalEditStaticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="modalEditStaticBackdropLabel">Edição de Residencial</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" v-on:click="limparDados(); removeAlert('modalEditStaticBackdrop')"></button>
            </div>
            <div class="modal-body">
              <div class="mb-12">
                <label for="inputResidencial" class="form-label">Residencial</label>
                <input type="text" class="form-control" id="inputResidencial" v-model="nome">
              </div>
              <div class="mb-12">
                <label for="inputEndereco" class="form-label">Endereço</label>
                <input type="text" class="form-control" id="inputEndereco" v-model="endereco">
              </div>
              <div class="mb-12">
                <label for="inputNumeroBlocos" class="form-label">Nº de Blocos</label>
                <input type="number" class="form-control" id="inputNumeroBlocos" v-model="numeroBlocos">
              </div>
              <div class="mb-12">
                <label for="inputDataCadastro" class="form-label">Data de Cadastro</label>
                <input type="date" class="form-control" id="inputDataCadastro" v-model="data">
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" v-on:click="limparDados(); removeAlert('modalEditStaticBackdrop')">Cancelar</button>
              <button type="button" class="btn btn-primary" v-on:click="atualizarResidencial(id, nome, endereco, numeroBlocos, data)">Atualizar</button>
            </div>
          </div>
        </div>
      </div>  
      <!-- END Modal Edit--> 

      <!-- Modal Delete -->
      <div class="modal fade" id="modalDelete" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalDeleteLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="modalDeleteLabel">Confirmar Exclusão</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" v-on:click="limparDados(); removeAlert('modalDelete'); removeDisableBtn()"></button>
            </div>
            <div class="modal-body">
              <p>
                Tem certeza que deseja excluir o residencial <br /> <span class="fw-bold"> {{nome}} </span> ? <br /> Todos os dados serão excluídos.
              </p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" v-on:click="limparDados(); removeAlert('modalDelete'); removeDisableBtn()">Cancelar</button>
              <button type="button" class="btn btn-primary" id="btnDelete" v-on:click="excluirResidencial(id)">Excluir</button>
            </div>
          </div>
        </div>
      </div>
      <!-- END Modal Delete -->

    </div>

  </div>
</template>

<script>
import LoaderComponent from './LoaderComponent.vue';
import { tratamentoError, formatarDataResidencial } from '@/helpers/utils';
import { getResidenciais, postResidencial, updateResidencial, deleteResidencial,  } from '@/service/residencialService';

export default {
  components: { LoaderComponent },

  data: function(){
    return {
      isLoading: false,
      token: sessionStorage.getItem('token'),
      
      id:0,
      nome:"",
      endereco:"",
      numeroBlocos: 0,
      data:"",

      arrayResidencial: [],
    }
  },

  methods: {
    cadastrarResidencial: async function($nome, $endereco, $numeroBlocos, $data){
      if($nome == "" || $endereco == "" || $numeroBlocos == "" || $data == ""){
        this.mostrarAlert("modalNewStaticBackdrop", "Todos os campos devem ser preenchidos!", "alert-warning");
        return
      } 
        
      try {
        let objResidencial = {nome: $nome, endereco: $endereco, numeroBlocos: $numeroBlocos, data: $data};
        let resp = await postResidencial(objResidencial, this.token);

        if(resp !== 200){
          this.mostrarAlert("modalNewStaticBackdrop", "Houve um erro ao cadastrar!", "alert-danger");
          return
        }

        this.arrayResidencial = await getResidenciais(this.token);  
        this.mostrarAlert("modalNewStaticBackdrop", "Residencial cadastrado com sucesso!", "alert-success");
        
      } catch (error) {
          const respError = tratamentoError(error);
          this.mostrarAlert("modalNewStaticBackdrop", respError, "alert-danger");

      } finally {
          setTimeout(() => {
            this.removeAlert("modalNewStaticBackdrop");
            this.limparDados();
          }, 2000)
      }
    },    

    atualizarResidencial: async function(id, $nome, $endereco, $numeroBlocos, $data){
      if($nome == "" || $endereco == "" || $numeroBlocos == "" || $data == ""){
        this.mostrarAlert("modalEditStaticBackdrop", "Todos os campos devem ser preenchidos!", "alert-warning");
        return
      } 
        
      try {
        let objResidencial = {nome: $nome, endereco: $endereco, numeroBlocos: $numeroBlocos, data: $data};
        let resp = await updateResidencial(id, objResidencial, this.token);

        if(resp !== 200){
          this.mostrarAlert("modalEditStaticBackdrop", "Houve um erro ao atualizar!", "alert-danger");
          return
        }
        
        this.arrayResidencial = await getResidenciais(this.token);  
        this.mostrarAlert("modalEditStaticBackdrop", "Residencial atualizado com sucesso!", "alert-success");
        
      } catch (error) {
        const respError = tratamentoError(error);
        this.mostrarAlert("modalEditStaticBackdrop", respError, "alert-danger");
      }
    },

    excluirResidencial: async function(id){
      try {
        let resp = await deleteResidencial(id, this.token);

        if(resp !== 200){
            this.mostrarAlert("modalDelete", "Houve um erro ao excluir!", "alert-danger");
            return
          }
        
        this.arrayResidencial = await getResidenciais(this.token);  
        this.mostrarAlert("modalDelete", "Residencial excluído com sucesso!", "alert-success"); 
        this.disableBtn();
       
      } catch (error) {
        const respError = tratamentoError(error);
        this.mostrarAlert("modalDelete", respError, "alert-danger");         
      }
    },

    selecionarResidencial: function(residencialSelecionado){
      this.id = residencialSelecionado.idRes;
      this.nome = residencialSelecionado.nomeRes;
      this.endereco = residencialSelecionado.logradouro;
      this.numeroBlocos = residencialSelecionado.numeroBlocos;
      this.data = this.formatarData(residencialSelecionado.dataRes,'EUA');
    },

    limparDados:function(){
      this.nome = "";
      this.endereco = "";
      this.numeroBlocos = "";
      this.data = "";
    },

    mostrarAlert: function(idLocal, mensagem, tipoAlert){
      const local = document.getElementById(idLocal);      
      const isSectionAlert = local.getElementsByTagName('section')[0];

      if(isSectionAlert) local.removeChild(isSectionAlert);
      
      const sectionAlert = document.createElement('section');
      sectionAlert.setAttribute('id', 'alertId');
      sectionAlert.style.margin = "0px 20vw";
      sectionAlert.style.textAlign = "center";
      sectionAlert.style.fontWeight = "bold";

      sectionAlert.innerHTML = 
        `<div class="alert ${tipoAlert} alert-dismissible" role="alert">
          <p style="margin-bottom: 0">${mensagem}</p>
        </div>`;
          
      local.appendChild(sectionAlert);
    },
    
    removeAlert: function(idLocal){
      const local = document.getElementById(idLocal);

      if(local.getElementsByTagName('section').length === 0) return;

      const sectionAlert = local.getElementsByTagName('section')[0];

      if(sectionAlert) local.removeChild(sectionAlert);
    }, 

    formatarData: function (dt, local) {
      return formatarDataResidencial(dt, local);
    },

    disableBtn: function (){
      let btn = document.getElementById("btnDelete");
      btn.classList.add("disabled");
    },

    removeDisableBtn: function (){
      let btn = document.getElementById("btnDelete");

      if(btn.classList.contains("disabled")){
        btn.classList.remove("disabled");
      }
    }
  },

  async mounted(){
    try {
      this.isLoading = true;
      this.arrayResidencial = await getResidenciais(this.token);      
    } catch (error) {
        this.mostrarAlert("cardBodyTable", "Houve um erro ao carregar as informações!", "alert-danger");      
        
        if(error.response.status === 401) {
          this.mostrarAlert("cardBodyTable", "Tempo de acesso finalizado.<br>Saia da aplicação e faça Login novamente!", "alert-danger");
          return
        } 
    } finally {
      this.isLoading = false;
    }
  },
 
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .container-fluid {
    padding-left: 65px;
  }

  .corEngecyber {
    background-color: #1271A9 !important;
  }

</style>
